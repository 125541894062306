import { Container, Typography, Grid, TextField, Button } from '@mui/material';
import React, {useState} from "react";
import Header from "../../components/Header";
import Footer from '../../components/Footer';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    mobile: ''
});

const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // Handle form submission logic here
};
  return (
    <>
    <Header value={'Contact Us'}/>
    <Container maxWidth="md">
            {/* <Typography variant="h5" gutterBottom>Contact Us</Typography> */}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3} mt={15}>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mobile"
                            name="mobile"
                            type="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Message"
                            name="message"
                            multiline
                            rows={4}
                            value={formData.message}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
        <Footer/>
    </>
  
  );
}

export default Contact;
