import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchBlog } from "../helpers/fetch2";
const app_url = process.env.REACT_APP_API_URL;
interface DataArray {
  title: string;
  video_link: string;
  description: string;
  createdAt: string;
}
interface DataObject {
  data: DataArray;
  loading: boolean;
  check: number;
  msg: string;
  status: number;
}

const initialState: DataObject = {
  data: {
    title: '',
    video_link: '',
    description: '',
    createdAt: ''
  },
  loading: false,
  check: 0,
  msg: "",
  status: 0
};
export const getAllTutorials = createAsyncThunk(
  "getAllTutorial",
  async () => {
    const result = await fetchBlog(`${app_url}/tutorial/getAllTutorial`);
    return result;
  }
);

const tutorialReducer = createSlice({
  name: "Tutorials",
  initialState,
  reducers: {
    reset: (state: any) => {
      state.loading = false;
      state.check = 0;
      state.msg = "";
      state.data = {};
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTutorials.pending, (state, action) => {
      state.check = 0;
      state.loading = false;
    });

    builder.addCase(getAllTutorials.fulfilled, (state, action) => {
      state.loading = true;
      if (action.payload.status === 1) {
        state.check = 1;
        state.data =action.payload;
      } else {
        state.check = 3;
        state.data = action.payload;
      }
    });

    builder.addCase(getAllTutorials.rejected, (state, action) => {
      state.check = 2;
      state.loading = false;
    });
  },
});
export const { reset } = tutorialReducer.actions;
export default tutorialReducer.reducer;
