import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { tokens } from '../theme';
export default function Footer() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <div>
      <Typography
        color="white"
        sx={{
          backgroundColor: colors.blueAccent[900],
          p: 1.5,
          color: "white",
          bottom: 0,
          left: 0,
          right: 0,
          position: 'fixed',
          fontSize: 10
        }}
      >
       Copyright &copy; 2024 Oneto1Advice Private Limited. All Rights Reserved
      </Typography>
    </div>
  )
}
