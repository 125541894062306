import Header from '../../components/Header'
import React, { useState, useEffect } from 'react';
import { Container, Grid, Avatar, TextField, Button } from '@mui/material';
import Footer from '../../components/Footer';


export default function Profile() {
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    mobile: ''
});

useEffect(() => {
    const fetchedUserData = {
        username: 'sunder',
        email: 'sunder@gmail.com',
        mobile: '7060532399'
    };
    setUserData(fetchedUserData);
}, []);

const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    setUserData({
        ...userData,
        [e.target.name]: e.target.value
    });
};

const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
};
  return (
    <div>
        <Header value={'Profile'}/>
        <Container maxWidth="md">
        
            <Grid container spacing={3} mt={15} alignItems="center">
              
                <Grid item xs={12}>
                    <TextField
                        label="Username"
                        name="username"
                        disabled
                        value={userData.username}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Email"
                        name="email"
                        disabled
                        value={userData.email}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Mobile"
                        name="mobile"
                        disabled
                        value={userData.mobile}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <TextField
                        label="Bio"
                        name="bio"
                        multiline
                        rows={4}
                        value={userData.bio}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Container>
        <Footer/>
    </div>
  )
}
