import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch22, fetch2 } from "../helpers/fetch2";



interface DataObject {
  data: any;
  loading: boolean;
  error: string;
  check: number;
};

const initialState: DataObject = {
  data: null,
  loading: false,
  error: "",
  check: 0,
};

export const unfollowUser = createAsyncThunk("unfollow", async (body: any) => {
  const result = await fetch2(
    `users/${encodeURIComponent(body.userId)}/unfollow`,
    body
  );
  return result;
});

export const followUser = createAsyncThunk("follow", async (body: any) => {
  const result = await fetch22(
    `users/${encodeURIComponent(body.id)}/follow`,
    body.userId,
    body.token
  );
  return result;
});

const followReducer = createSlice({
  name: "follow",
  initialState,
  reducers: {
    clearState: (state: any) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(unfollowUser.pending, (state, action) => {
      state.loading = false;
    });

    builder.addCase(unfollowUser.fulfilled, (state, action) => {
      state.loading = true;
      if (action.payload.status === 1) {
        state.check = 1;
        state.data =action.payload;
      } else {
        state.check = 0;
        state.data =action.payload;
      }
    });

    builder.addCase(unfollowUser.rejected, (state, action) => {
      state.check = 2;
      state.loading = false;
    });
  },
});

export default followReducer.reducer;
