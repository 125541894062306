import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { SwapVert } from "@mui/icons-material";
import CardComp from "../../components/CardComp";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { getParents } from "../../reducers/usersReducer";
import { RootState } from "../../app/store";
import { addId, logout, signinUser, signupUser } from "../../reducers/authReducer";
import CheckPayment from "../../components/CheckPayment";
import { getPaymentVerification } from "../../reducers/paymentReducer";
import moment from "moment";
import { persistor } from "../../app/store";
import {  useTheme, useMediaQuery } from "@mui/material";
import StatBox from "../../components/StatBox";
import { tokens } from "../../theme";
import { lossProfitProduct } from "../../reducers/lossProfitReducer";
import LoginWithEmail from "../../components/LoginWithEmail";
import StockWithPopUpList from "../../components/StockWithPopUpList";
import Header from "../../components/HeaderHome";
import FooterHome from "../../components/FooterHome";
import { requestPermission } from "../../services/firebase";
import CircularProgress from '@mui/material/CircularProgress';
function Service() {
  const theme = useTheme();
  const [loginPopUp, setLoginPopUp] = useState(false);
  const colors = tokens(theme.palette.mode);
  const [checkData] = useState(1);
  const [checkPayment, setCheckPayment] = useState(false);
  const [profitLoss, setProfitLoss] = useState({
    progress: 0,
    increase: "+0%",
  });
  const [profitLoss30, setProfitLoss30] = useState({
    progress: 0,
    increase: "+0%",
  });

  const [profitLoss7, setProfitLoss7] = useState({
    progress: 0,
    increase: "+0%",
  });

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('home');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(true);
  const [openStocks, setOpenStocks] = useState(false);
  const { loading: loadingLogin, data: dataLogin, check } = useSelector((state: any) => state.auth);
  const { loading, error, data } = useSelector(
    (state: RootState) => state.userlist
  );


  const memoizedState = useMemo(() => ({
    data,
    error,
    loading
  }), [data, error, loading]);

  const {
    loading: loadingProduct,
    error: errorProduct,
    data: dataProduct,
  } = useSelector((state: RootState) => state.loss_profit);

  const { data: dataPayment } = useSelector(
    (state: RootState) => state.payment
  );


  const handleClosePopUp = () => {
    setLoginPopUp(false)
  };

  const handleSubmitPopUp = (data: any) => {
    let username = data.name;
    let address = data.address;
    let mobile = data.mobile;
    let dob = data.dob;
    let email = data.email;
    let password = data.password;
    requestPermission()
      .then((token: any) => {
        let fcmtoken = token;
        dispatch(signupUser({ username, mobile, address, dob, email, password, fcmtoken }));
      })
      .catch((error: any) => {
        console.error('Error requesting permission:', error);
      });
  }

  const handleSubmitLoginPopUp = (data: any) => {
    let email = data.email;
    let password = data.password;
    requestPermission()
      .then((token: any) => {
        let fcmtoken = token;
        dispatch(signinUser({ email, password, fcmtoken }));
      })
      .catch((error: any) => {
        console.error('Error requesting permission:', error);
      });
  }

  const handleTabClick = (newValue: string, event: any) => {
    if (newValue === "setting") {
      setAnchorEl(event.currentTarget);
    } else if (newValue === 'recharge') {
      if (check === 1) {
        navigate('/recharge')
      } else {
        setLoginPopUp(true);
      }

    } else if (newValue === 'login') {
      setLoginPopUp(true);
    } else if (newValue === 'logout') {
      logoutFun()
    } else {
      navigate('/')
    }
    setValue(newValue);
  }

  const handleClose = (value: any) => {
    setAnchorEl(null);
    if (value === 'blog') {
      navigate('/blog')
    } else if (value === 'profile') {
      if (check === 1) {
        navigate('/profile')
      } else {
        setLoginPopUp(true);
      }

    } else if (value === 'about') {
      navigate('/about')
    } else if (value === 'privacy') {
      navigate('/privacy')
    } else if (value === 'contact') {
      navigate('/contact')
    } else if (value === 'tutorial') {
      navigate('/tutorial')
    }
  };

  const logoutFun = async () => {
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    dispatch(logout());
    navigate("/");
  };


  const callProduct = (value: any) => {
    if ((value.name).toUpperCase() === "Derivative".toUpperCase()) { //1
      if (check === 1) {
        navigate("/products", { state: { token: dataLogin, type: 1 } });
      } else {
        setLoginPopUp(true);
      }

    } else if ((value.name).toUpperCase() === "Bucket".toUpperCase()) { //3
      if (check === 1) {
        // navigate("/products", { state: { token:  dataLogin.token, type: 3 } });
      } else {
        setLoginPopUp(true);
      }

    } else if ((value.name).toUpperCase() === "Stocks".toUpperCase()) { //2
      if (check === 1) {
        setOpenStocks(true);

        //navigate("/products", { state: { token:  dataLogin.token, type: 2 } });
      } else {
        setLoginPopUp(true);
      }

    } else if ((value.name).toUpperCase() === "Commodity".toUpperCase()) { //4
      if (check === 1) {
        // navigate("/products", { state: { token: dataLogin.token, type: 4 } });
      } else {
        setLoginPopUp(true);
      }

    } else if ((value.name).toUpperCase() === "Currency".toUpperCase()) { //5
      if (check === 1) {
        // navigate("/products", { state: { token:  dataLogin.token, type: 5 } });
      } else {
        setLoginPopUp(true);
      }

    }
  }



  useEffect(() => {
    dispatch(addId());
  });

  useEffect(() => {
    let _id = dataLogin?._id;
    let token = dataLogin?.token;
    dispatch(getParents({ _id, token }));
  }, [dataLogin]);

  useEffect(() => {
    let _id = dataLogin?._id;
    dispatch(getPaymentVerification({ _id }));

    if (dataPayment) {
      var create = moment(dataPayment?.data[0]?.createdAt).format(
        "DD-MMM-YYYY"
      );
      var payment = moment(dataPayment?.data[0]?.payment).format("DD-MMM-YYYY");
      var current = moment(new Date()).format("DD-MMM-YYYY");
      if (dataPayment?.data[0]?.free === 1) {
        const diffTime: any = Math.abs(+new Date(current) - +new Date(create));
        if (diffTime / (1000 * 3600 * 24) > 7) {
          setCheckPayment(true);
        }
      } else {
        const diffTime: any = Math.abs(+new Date(current) - +new Date(payment));
        if (diffTime / (1000 * 3600 * 24) > 30) {
          setCheckPayment(true);
        }
      }
    }
  }, [dataLogin]);
  function checkProfit(profit: any) {
    var create = moment(profit.createdAt).format("DD-MMM-YYYY");
    var current = moment(new Date()).format("DD-MMM-YYYY");
    return profit.loss_profit === "1" && create === current;
  }



  function checkLoss(profit: any) {
    var create = moment(profit.createdAt).format("DD-MMM-YYYY");
    var current = moment(new Date()).format("DD-MMM-YYYY");
    return profit.loss_profit === "0" && create === current;
  }

  function checkProfit30(profit: any) {
    const currentDate = new Date();
    const currentDateTime = currentDate.getTime();
    const last30DaysDate = new Date(
      currentDate.setDate(currentDate.getDate() - 30)
    );
    const last30DaysDateTime = last30DaysDate.getTime();
    const last30DaysList = dataProduct.data
      .filter((x: any) => {
        const elementDateTime = new Date(x.createdAt).getTime();
        if (
          elementDateTime <= currentDateTime &&
          elementDateTime > last30DaysDateTime &&
          profit.loss_profit === "1"
        ) {
          return true;
        }
        return false;
      })
      .sort((a: any, b: any) => {
        let dataa: any = new Date(b.createdAt);
        let ff: any = new Date(a.createdAt);
        return dataa - ff;
      });
    return profit.loss_profit === "1" && last30DaysList;
  }

  function checkLoss30(profit: any) {
    const currentDate = new Date();
    const currentDateTime = currentDate.getTime();
    const last30DaysDate = new Date(
      currentDate.setDate(currentDate.getDate() - 30)
    );
    const last30DaysDateTime = last30DaysDate.getTime();
    const last30DaysList = dataProduct.data
      .filter((x: any) => {
        const elementDateTime = new Date(x.createdAt).getTime();
        if (
          elementDateTime <= currentDateTime &&
          elementDateTime > last30DaysDateTime
        ) {
          return true;
        }
        return false;
      })
      .sort((a: any, b: any) => {
        let dataa: any = new Date(b.createdAt);
        let ff: any = new Date(a.createdAt);
        return dataa - ff;
      });
    return profit.loss_profit === "0" && last30DaysList;
  }

  function checkProfit7(profit: any) {
    const currentDate = new Date();
    const currentDateTime = currentDate.getTime();
    const last7DaysDate = new Date(
      currentDate.setDate(currentDate.getDate() - 7)
    );
    const last7DaysDateTime = last7DaysDate.getTime();
    const last7DaysList = dataProduct.data
      .filter((x: any) => {
        const elementDateTime = new Date(x.createdAt).getTime();
        if (
          elementDateTime <= currentDateTime &&
          elementDateTime > last7DaysDateTime &&
          profit.loss_profit === "1"
        ) {
          return true;
        }
        return false;
      })
      .sort((a: any, b: any) => {
        let dataa: any = new Date(b.createdAt);
        let ff: any = new Date(a.createdAt);
        return dataa - ff;
      });
    return profit.loss_profit === "1" && last7DaysList;
  }

  function checkLoss7(profit: any) {
    const currentDate = new Date();
    const currentDateTime = currentDate.getTime();
    const last7DaysDate = new Date(
      currentDate.setDate(currentDate.getDate() - 7)
    );
    const last7DaysDateTime = last7DaysDate.getTime();
    const last7DaysList = dataProduct.data
      .filter((x: any) => {
        const elementDateTime = new Date(x.createdAt).getTime();
        if (
          elementDateTime <= currentDateTime &&
          elementDateTime > last7DaysDateTime
        ) {
          return true;
        }
        return false;
      })
      .sort((a: any, b: any) => {
        let dataa: any = new Date(b.createdAt);
        let ff: any = new Date(a.createdAt);
        return dataa - ff;
      });
    return profit.loss_profit === "0" && last7DaysList;
  }



  useEffect(() => {
    dispatch(lossProfitProduct());
    if (dataProduct) {
      //current date
      const profit: any = dataProduct.data.filter(checkProfit);
      const loss: any = dataProduct.data.filter(checkLoss);
      const profitLength: number = profit.length;
      const lossLength: number = loss.length;
      const total_today_product: number = profitLength + lossLength === 0 ? 1 : profitLength + lossLength;
      const total_profit = +((profitLength * 100) / total_today_product);
      const progress = total_profit / 100;
      setProfitLoss({ progress: progress, increase: "+" + total_profit + "%" });


      //30 days
      const profit30: any = dataProduct.data.filter(checkProfit30);
      const loss30: any = dataProduct.data.filter(checkLoss30);
      const profitLength30: number = profit30.length;
      const lossLength30: number = loss30.length;
      const total_today_product30: number = profitLength30 + lossLength30 === 0 ? 1 : profitLength30 + lossLength30;
      const total_profit30 = Math.floor((profit30.length * 100) / total_today_product30);
      const progress30 = total_profit30 / 100;
      setProfitLoss30({
        progress: progress30,
        increase: "+" + total_profit30 + "%",
      });

      //7 days
      const profit7: any = dataProduct.data.filter(checkProfit7);
      const loss7: any = dataProduct.data.filter(checkLoss7);
      const profitLength7: number = profit7.length;
      const lossLength7: number = loss7.length;
      const total_today_product7: number = profitLength7 + lossLength7 === 0 ? 1 : profitLength7 + lossLength7;
      const total_profit7 = Math.floor((profit7.length * 100) / total_today_product7);
      const progress7 = total_profit7 / 100;

      setProfitLoss7({
        progress: progress7,
        increase: "+" + total_profit7 + "%",
      });
    }
  }, []);

  function handleStocksPopUpData(item: any) {

    navigate("/stocks", { state: { token: dataLogin || '', type: 2, child: item } });
  }

  useEffect(() => {
    if (check === 1) {
      setLoginPopUp(false)
    }
  }, [check])


  const handleCloseStocksPopUp = () => {
    setOpenStocks(false)
  }

  return (
    <div>

      <Box sx={{ mt: 15, }}>
        <Header />
        {memoizedState.loading ? <CircularProgress style={{
           position: 'fixed',
          top: '55%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }} /> :
          <>
            <Box
              component="main"
              p={1}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(9, 1fr)',
                  gridAutoRows: '150px',
                  gap: '20px',
                  mb: 1,
                  '@media (max-width: 600px)': {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  },
                  '@media (min-width: 601px) and (max-width: 900px)': {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  },
                }}
              >
                <Box
                  gridColumn="span 3"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={1}
                  sx={{ backgroundColor: colors.blueAccent[100], borderWidth: 2, borderColor: colors.blueAccent[100] }}
                >
                  <StatBox
                    title="Profit(G) & Loss(R)"
                    subtitle="Daily"
                    progress={profitLoss.progress}
                    increase={profitLoss.increase}
                    icon={
                      <SwapVert
                        sx={{ color: colors.white[100], fontSize: "30px" }}
                      />
                    }
                  />
                </Box>

                <Box
                  gridColumn="span 3"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={1}
                  sx={{ backgroundColor: colors.blueAccent[200],  borderWidth: 2, borderColor: colors.blueAccent[100] }}
                >
                  <StatBox
                    title="Profit(G) & Loss(R)"
                    subtitle="Weekly"
                    progress={profitLoss7.progress}
                    increase={profitLoss7.increase}
                    icon={
                      <SwapVert
                        sx={{ color: colors.white[100], fontSize: "30px" }}
                      />
                    }
                  />
                </Box>

                <Box
                  gridColumn="span 3"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={1}
                  sx={{ backgroundColor: colors.blueAccent[300],  borderWidth: 2, borderColor: colors.blueAccent[100] }}
                >
                  <StatBox
                    title="Profit(G) & Loss(R)"
                    subtitle="Monthly"
                    progress={profitLoss30.progress}
                    increase={profitLoss30.increase}
                    icon={
                      <SwapVert
                        sx={{ color: colors.white[100], fontSize: "30px" }}
                      />
                    }
                  />
                </Box>
              </Box>
              {checkPayment && <CheckPayment />}
              <CardComp dataValue={data || ''} type={checkData} token={dataLogin || ''} loginPopUp={loginPopUp} callProduct={callProduct} />
            </Box>
            <FooterHome />
          </>
        }
      </Box>
      {loginPopUp ?
        <LoginWithEmail open={open} handleClose={handleClosePopUp} handleSubmitPopUp={handleSubmitPopUp} message={dataLogin || ''} handleSubmitLoginPopUp={handleSubmitLoginPopUp} loadingLogin={loadingLogin}/>
        : null}
      {openStocks ? <StockWithPopUpList handleCloseStocksPopUp={handleCloseStocksPopUp} /> : null}
      {openStocks ? <StockWithPopUpList open={openStocks} handleCloseStocksPopUp={handleCloseStocksPopUp} handleStocksPopUpData={handleStocksPopUpData} /> : null}

    </div>
  );
}

export default Service;
