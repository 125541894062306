import { Typography, useTheme, Menu, MenuItem, AppBar, IconButton, Toolbar, Grid, Hidden, Alert } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { tokens } from '../theme';
import { useNavigate } from 'react-router-dom';
import MotionTextLoop from './MotionText ';
import MenuIcon from '@mui/icons-material/Menu';
import logoImage from '../assets/logo.png';
import LoginWithEmail from './LoginWithEmail';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { logout, signinUser, signupUser } from '../reducers/authReducer';
import StockWithPopUpList from './StockWithPopUpList';
import { persistor } from '../app/store';
import { requestPermission } from '../services/firebase';
// import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PersonIcon from '@mui/icons-material/Person'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import HelpIcon from '@mui/icons-material/Help'
import SchoolIcon from '@mui/icons-material/School'
import AssignmentIcon from '@mui/icons-material/Assignment'
import InfoIcon from '@mui/icons-material/Info'
import SettingsIcon from '@mui/icons-material/Settings'
import HomeIcon from '@mui/icons-material/Home'
import ServiceIcon from '@mui/icons-material/Category'
import LockIcon from '@mui/icons-material/Lock'
import AccountCircleIcons from '@mui/icons-material/AccountCircle'
import './headerHome.css'
import PaymentIcon from '@mui/icons-material/Payment'
import LogoutIcon from '@mui/icons-material/Logout'
import CallIcon from '@mui/icons-material/Call'
import ChatIcon from '@mui/icons-material/Chat'
export default function Header() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { loading, data: dataLogin, check } = useSelector((state: any) => state.auth);
  const [openStocks, setOpenStocks] = useState(false);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorElSetting, setAnchorElSetting] = useState(null); // State to manage anchor element for Menu
  const [visible, setVisible] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);

  const colors = tokens(theme.palette.mode);
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
 
  const handleTouchStart = (itemName: any) => {
    setHoveredItem(itemName);

  };

  const goBack = () => {
    navigate('/')
  };


  function handleStocksPopUpData(item: any) {
    navigate("/stocks", { state: { token: dataLogin, type: 2, child: item } });
  }
  const handleSubmitPopUp = (data: any) => {
    let username = data.name;
    let address = data.address;
    let mobile = data.mobile;
    let dob = data.dob;
    let email = data.email;
    let password = data.password;
    requestPermission()
      .then((token: any) => {
        let fcmtoken = token;
        dispatch(signupUser({ username, mobile, address, dob, email, password, fcmtoken }));
      })
      .catch((error: any) => {
        console.error('Error requesting permission:', error);
      });
  }

  const handleClosePopUp = () => {
    setLoginPopUp(false)
  };

  const handleTouchEnd = () => {
    setHoveredItem(null);
  };

  const isHovered = (itemName: any) => {
    return hoveredItem === itemName;
  };


  const handleSubmitLoginPopUp = (data: any) => {
    let email = data.email;
    let password = data.password;
    requestPermission()
      .then((token: any) => {
        let fcmtoken = token;
        dispatch(signinUser({ email, password, fcmtoken }));
      })
      .catch((error: any) => {
        console.error('Error requesting permission:', error);
      });

  }

  useEffect(() => {

    if (check === 1) {
      setLoginPopUp(false)
    }
  }, [check])

  const handleItemClick = (text: any) => {
    if (text === "Service") {
      setAnchorEl(null);
      setAnchorElSetting(null);
      navigate('/service')
    } else if (text === "Pay") {
      setAnchorEl(null);
      setAnchorElSetting(null);
      if (check === 1) {
        navigate('/recharge')
      } else {
        setLoginPopUp(true);
      }
    } else if (text === "Logout") {
      setAnchorEl(null);
      setAnchorElSetting(null);
      logoutFun()
    } else if (text === "SignIn") {
      setAnchorEl(null);
      setAnchorElSetting(null);
      setLoginPopUp(true);
    } else if (text === "Setting") {

    } else if (text === "Home") {
      setAnchorEl(null);
      setAnchorElSetting(null);
      navigate('/')
    }
  };


  const logoutFun = async () => {
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    dispatch(logout());
    navigate("/");
  };


  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseStocksPopUp = () => {
    setOpenStocks(false)
  }


  const handleOpenDropdownSetting = (event: any) => {
    setAnchorElSetting(event.currentTarget);
    let data: any = "Setting"
    setHoveredItem(data);
  };


  const handleOpenDropdownSetting1 = (event: any) => {
    setAnchorElSetting(event.currentTarget);
    let data: any = "Setting"
    setHoveredItem(data);

  }

  const handleCloseDropdownSetting1 = () => {
    setAnchorElSetting(null);
    setHoveredItem(null);
  }

  const handleCloseDropdownSetting = () => {
    setAnchorElSetting(null);
    setHoveredItem(null);
    setAnchorEl(null);
  };




  const handleItemClickSetting = (item: any) => {
    if (item === "Blogs") {
      handleCloseDropdownSetting();
      navigate('/blog')
    } else if (item === "Tutorials") {
      handleCloseDropdownSetting();
      navigate('/tutorial')
    } else if (item === "About Us") {
      handleCloseDropdownSetting();
      navigate('/about')
    } else if (item === "Profile") {
      handleCloseDropdownSetting();
      if (check === 1) {
        navigate('/profile')
      } else {
        setLoginPopUp(true);
      }
    } else if (item === "Support") {
      setVisible(!visible)
    } else if (item === "Logout") {
      handleCloseDropdownSetting();
      logoutFun()
    } else if (item === "SignIn") {
      handleCloseDropdownSetting();
      setLoginPopUp(true);
    } else if (item === "Privacy & Policy") {
      handleCloseDropdownSetting();
      navigate('/privacy')
    }
  };


  const callPhoneNumber = () =>{
     navigate('/call')
  }

  const chatQuery = () =>{
    alert("coming soon")
   //navigate('/chat')
 }

  return (

    <div style={{
      backgroundColor: colors.blueAccent[900],
      color: "white",
      top: 0,
      left: 0,
      zIndex: 99,
      right: 0,
      position: 'fixed',
    }}>

      <div style={{
        backgroundColor: colors.redAccent[900],
        color: "white",
        left: 0,
        right: 0,

      }}>
        <MotionTextLoop text="Stock Markets are Subject to market risk. No Guaranteed Returns in Stock Market." speed={20}
        />
      </div>
      <Toolbar>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item container direction="row" xs={6} sm={6} md={3}>
            <img src={logoImage} alt="Sample" className='img' />
            <Typography onMouseEnter={() => handleTouchStart("Oneto1Advice")}
              onMouseLeave={handleTouchEnd}
              style={{ marginLeft: 10, color: isHovered("Oneto1Advice") ? 'black' : 'white', cursor: isHovered("Oneto1Advice") ? 'pointer' : 'auto', alignSelf: 'center' }}>Oneto1Advice</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={9} container justifyContent="flex-end">

            <Hidden mdUp>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>


              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: {
                    width: '100%', // or any other desired width
                  },
                }}
              >
                <div style={{ display: 'flex' }}>
                  <MenuItem onClick={() => handleItemClick('Home')}
                    onMouseEnter={() => handleTouchStart("Home")}
                    onMouseLeave={handleTouchEnd}
                    onTouchStart={() => handleTouchStart("Home")}
                    onTouchEnd={handleTouchEnd}
                    style={{ color: isHovered("Home") ? 'black' : 'red', cursor: isHovered("Home") ? 'pointer' : 'auto' }}
                  >
                    <ListItemIcon>
                      <HomeIcon style={{ color: isHovered("Home") ? 'black' : 'red', cursor: isHovered("Home") ? 'pointer' : 'auto' }} />
                    </ListItemIcon>
                    <ListItemText primary="Home" />

                  </MenuItem></div>
                <Divider variant="middle" />
                <div style={{ display: 'flex' }}>
                  <MenuItem onClick={() => handleItemClick('Service')}
                    onMouseEnter={() => handleTouchStart("Service")}
                    onMouseLeave={handleTouchEnd}
                    onTouchStart={() => handleTouchStart("Service")}
                    onTouchEnd={handleTouchEnd}
                    style={{ color: isHovered("Service") ? 'black' : 'red', cursor: isHovered("Service") ? 'pointer' : 'auto', justifySelf: 'flex-start' }}
                  > <ListItemIcon>
                      <ServiceIcon style={{ color: isHovered("Service") ? 'black' : 'red', cursor: isHovered("Service") ? 'pointer' : 'auto', justifySelf: 'flex-start' }} />
                    </ListItemIcon>
                    <ListItemText primary="Service" /> </MenuItem></div>
                <Divider variant="middle" />
                <div>

                  <MenuItem onMouseEnter={() => handleTouchStart("SignIn")}
                    onMouseLeave={handleTouchEnd}
                    onTouchStart={() => handleTouchStart("Pay")}
                    onTouchEnd={handleTouchEnd}
                    style={{ color: isHovered("Pay") ? 'black' : 'red', cursor: isHovered("Pay") ? 'pointer' : 'auto', }} onClick={() => handleItemClick('Pay')}>

                    <ListItemIcon>
                      <PaymentIcon style={{ color: isHovered("Pay") ? 'black' : 'red', cursor: isHovered("Pay") ? 'pointer' : 'auto', }} />
                    </ListItemIcon>
                    <ListItemText primary="Pay" />
                  </MenuItem>

                </div>

                <Divider variant="middle" />
                <Grid item>
                  <MenuItem
                    onClick={handleOpenDropdownSetting1}
                    style={{ color: isHovered("Setting") ? 'black' : 'red', cursor: isHovered("Setting") ? 'pointer' : 'auto' }}
                  >
                    <ListItemIcon>
                      <SettingsIcon style={{ color: isHovered("Setting") ? 'black' : 'red', cursor: isHovered("Setting") ? 'pointer' : 'auto' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Setting" />
                    {anchorElSetting ? <ExpandLess /> : <ExpandMore />}</MenuItem>
                  <Menu
                    anchorEl={anchorElSetting}
                    open={Boolean(anchorElSetting)}
                    onClose={handleCloseDropdownSetting1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >

                    <MenuItem onClick={() => handleItemClickSetting('About Us')}>
                      <ListItemIcon>
                        <InfoIcon style={{ color: isHovered("About Us") ? 'black' : 'black', cursor: isHovered("About Us") ? 'pointer' : 'auto' }}/>
                      </ListItemIcon>
                      <ListItemText primary="About Us" />
                    </MenuItem>
                    <Divider variant="fullWidth" />
                    <MenuItem onClick={() => handleItemClickSetting('Profile')}>
                      <ListItemIcon>
                        <PersonIcon style={{ color: isHovered("Profile") ? 'black' : 'black', cursor: isHovered("Profile") ? 'pointer' : 'auto' }}/>
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </MenuItem>
                    <Divider variant="fullWidth" />
                    <MenuItem onClick={() => handleItemClickSetting('Blogs')}>
                      <ListItemIcon>
                        <AssignmentIcon style={{ color: isHovered("Blogs") ? 'black' : 'black', cursor: isHovered("Blogs") ? 'pointer' : 'auto' }}/>
                      </ListItemIcon>
                      <ListItemText primary="Blogs" />
                    </MenuItem>
                    <Divider variant="fullWidth" />
                    <MenuItem onClick={() => handleItemClickSetting('Tutorials')}>
                      <ListItemIcon>
                        <SchoolIcon style={{ color: isHovered("Tutorials") ? 'black' : 'black', cursor: isHovered("Tutorials") ? 'pointer' : 'auto' }}/>
                      </ListItemIcon>
                      <ListItemText primary="Tutorials" />
                    </MenuItem>
                    <Divider variant="fullWidth" />
                    <MenuItem
                      onClick={() => handleItemClickSetting('Support')}
                    >
                      <ListItemIcon>
                        <HelpIcon style={{ color: isHovered("Support") ? 'black' : 'black', cursor: isHovered("Support") ? 'pointer' : 'auto' }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Support" />
                      {visible ? <ExpandLess /> : <ExpandMore />}
                    </MenuItem>

                    <Divider variant="fullWidth" />
                    {visible ? <><MenuItem onClick={callPhoneNumber}>
                      <ListItemIcon>
                        <CallIcon style={{ fontSize: 'small' }} />
                      </ListItemIcon>
                      <ListItemText secondary="Call" />
                    </MenuItem><Divider variant="middle" /><MenuItem onClick={chatQuery}>
                        <ListItemIcon>
                          <ChatIcon style={{ fontSize: 'small' }} />
                        </ListItemIcon>
                        <ListItemText secondary="Chat" />
                      </MenuItem>
                      <Divider variant="middle" /></>
                      : null}
                    <MenuItem onClick={() => handleItemClickSetting('Privacy & Policy')}>
                      <ListItemIcon>
                        <LockIcon style={{ color: isHovered("Privacy & Policy") ? 'black' : 'black', cursor: isHovered("Privacy & Policy") ? 'pointer' : 'auto' }}/>
                      </ListItemIcon>
                      <ListItemText primary="Privacy & Policy" />
                    </MenuItem>



                    <Divider variant="middle" />
                    <div style={{ display: 'flex' }}>
                      {check === 1 ? <MenuItem onMouseEnter={() => handleTouchStart("Logout")}
                        onMouseLeave={handleTouchEnd}
                        onClick={() => handleItemClick('Logout')}
                        onTouchStart={() => handleTouchStart("Logout")}
                        onTouchEnd={handleTouchEnd}
                        style={{ color: isHovered("Logout") ? 'black' : 'black', cursor: isHovered("Logout") ? 'pointer' : 'auto' }}>

                        <ListItemIcon>
                          <LogoutIcon style={{ color: isHovered("Logout") ? 'black' : 'black', cursor: isHovered("Logout") ? 'pointer' : 'auto' }} />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </MenuItem>
                        : <MenuItem onMouseEnter={() => handleTouchStart("SignIn")}
                          onMouseLeave={handleTouchEnd}
                          onClick={() => handleItemClick('SignIn')}
                          onTouchStart={() => handleTouchStart("SignIn")}
                          onTouchEnd={handleTouchEnd}
                          style={{ color: isHovered("SignIn") ? 'black' : 'black', cursor: isHovered("SignIn") ? 'pointer' : 'auto' }}>

                          <ListItemIcon>
                            <AccountCircleIcons style={{ color: isHovered("SignIn") ? 'black' : 'black', cursor: isHovered("SignIn") ? 'pointer' : 'auto' }} />
                          </ListItemIcon>
                          <ListItemText primary="SignIn" />
                        </MenuItem>}
                    </div>
                  </Menu>
                </Grid>
              </Menu>

              {loginPopUp ?
                <LoginWithEmail open={open} handleClose={handleClosePopUp} handleSubmitPopUp={handleSubmitPopUp} message={dataLogin || ''} handleSubmitLoginPopUp={handleSubmitLoginPopUp} />
                : null}
              {openStocks ? <StockWithPopUpList handleCloseStocksPopUp={handleCloseStocksPopUp} /> : null}
              {openStocks ? <StockWithPopUpList open={openStocks} handleCloseStocksPopUp={handleCloseStocksPopUp} handleStocksPopUpData={handleStocksPopUpData} /> : null}
            </Hidden>

            <Hidden mdDown>
              <Grid item>
                <Grid container direction="row" spacing={8} alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Typography onMouseEnter={() => handleTouchStart("Home")}
                      onMouseLeave={handleTouchEnd}
                      onClick={() => handleItemClick('Home')}
                      style={{ color: isHovered("Home") ? 'black' : 'white', cursor: isHovered("Home") ? 'pointer' : 'auto' }}>Home</Typography>
                  </Grid>
                  <Grid item>
                    <Typography onMouseEnter={() => handleTouchStart("Service")}
                      onMouseLeave={handleTouchEnd}
                      onClick={() => handleItemClick('Service')}
                      style={{ color: isHovered("Service") ? 'black' : 'white', cursor: isHovered("Service") ? 'pointer' : 'auto' }}>Service</Typography>
                  </Grid>
                  <Grid item>
                    <Typography onMouseEnter={() => handleTouchStart("Pay")}
                      onMouseLeave={handleTouchEnd}
                      onClick={() => handleItemClick('Pay')}
                      style={{ color: isHovered("Pay") ? 'black' : 'white', cursor: isHovered("Pay") ? 'pointer' : 'auto' }}>Pay</Typography>
                  </Grid>


                  <Grid item>
                    <Typography
                      onClick={handleOpenDropdownSetting}
                      onMouseEnter={handleOpenDropdownSetting}
                      onTouchStart={handleOpenDropdownSetting}
                      onTouchEnd={handleCloseDropdownSetting}
                      style={{
                        color: isHovered("Setting") ? 'black' : 'white', cursor: isHovered("Setting") ? 'pointer' : 'auto', display: 'flex',
                        alignItems: 'center'
                      }}>Setting {anchorElSetting ? <ExpandLess /> : <ExpandMore />}</Typography>
                    <Menu
                      anchorEl={anchorElSetting}
                      open={Boolean(anchorElSetting)}
                      onClose={handleCloseDropdownSetting}

                      style={{ marginTop: 10 }}
                    >
                      <MenuItem onClick={() => handleItemClickSetting('About Us')}>
                        <ListItemIcon>
                          <InfoIcon style={{ color: isHovered("About Us") ? 'black' : 'black', cursor: isHovered("About Us") ? 'pointer' : 'auto' }} />
                        </ListItemIcon>
                        <ListItemText primary="About Us" />
                      </MenuItem>
                      <Divider variant="fullWidth" />
                      <MenuItem onClick={() => handleItemClickSetting('Profile')}>
                        <ListItemIcon>
                          <PersonIcon style={{ color: isHovered("Profile") ? 'black' : 'black', cursor: isHovered("Profile") ? 'pointer' : 'auto' }} />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                      </MenuItem>
                      <Divider variant="fullWidth" />
                      <MenuItem onClick={() => handleItemClickSetting('Blogs')}>
                        <ListItemIcon>
                          <AssignmentIcon style={{ color: isHovered("Blogs") ? 'black' : 'black', cursor: isHovered("Blogs") ? 'pointer' : 'auto' }} />
                        </ListItemIcon>
                        <ListItemText primary="Blogs" />
                      </MenuItem>
                      <Divider variant="fullWidth" />

                      <MenuItem onClick={() => handleItemClickSetting('Tutorials')}>
                        <ListItemIcon>
                          <SchoolIcon style={{ color: isHovered("Tutorials") ? 'black' : 'black', cursor: isHovered("Tutorials") ? 'pointer' : 'auto' }} />
                        </ListItemIcon>
                        <ListItemText primary="Tutorials" />
                      </MenuItem>
                      <Divider variant="fullWidth" />




                      <MenuItem
                        onClick={() => handleItemClickSetting('Support')}
                      >

                        <ListItemIcon>
                          <HelpIcon style={{ color: isHovered("Support") ? 'black' : 'black', cursor: isHovered("Support") ? 'pointer' : 'auto' }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Support" />
                        {visible ? <ExpandLess /> : <ExpandMore />}
                      </MenuItem>

                      <Divider variant="fullWidth" />

                      {visible ? <><MenuItem  onClick={() => callPhoneNumber()}>
                        <ListItemIcon>
                          <CallIcon style={{ fontSize: 'small' }} />
                        </ListItemIcon>
                        <ListItemText secondary="Call" />
                      </MenuItem><Divider variant="middle" /><MenuItem onClick={chatQuery}>
                          <ListItemIcon>
                            <ChatIcon style={{ fontSize: 'small' }} />
                          </ListItemIcon>
                          <ListItemText secondary="Chat" />
                        </MenuItem>
                        <Divider variant="middle" /></>
                        : null}




                      <MenuItem onClick={() => handleItemClickSetting('Privacy & Policy')}>
                        <ListItemIcon>
                          <LockIcon style={{ color: isHovered("Privacy & Policy") ? 'black' : 'black', cursor: isHovered("Privacy & Policy") ? 'pointer' : 'auto' }} />
                        </ListItemIcon>
                        <ListItemText primary="Privacy & Policy" />
                      </MenuItem>

                      <Divider variant="fullWidth" />
                      <div>
                        {check === 1 ? <MenuItem
                          onClick={() => handleItemClickSetting('Logout')}
                          style={{ color: isHovered("Logout") ? 'black' : 'black', cursor: isHovered("Logout") ? 'pointer' : 'auto' }}>
                          <ListItemIcon>
                            <LogoutIcon style={{ color: isHovered("Logout") ? 'black' : 'black', cursor: isHovered("Logout") ? 'pointer' : 'auto' }} />
                          </ListItemIcon>
                          <ListItemText primary="Logout" />
                        </MenuItem>
                          : <MenuItem
                            onClick={() => handleItemClickSetting('SignIn')}
                            style={{ color: isHovered("SignIn") ? 'black' : 'black', cursor: isHovered("SignIn") ? 'pointer' : 'auto' }}>
                            <ListItemIcon>
                              <AccountCircleIcons style={{ color: isHovered("SignIn") ? 'black' : 'black', cursor: isHovered("SignIn") ? 'pointer' : 'auto' }} />
                            </ListItemIcon>
                            <ListItemText primary="SignIn" />
                          </MenuItem>}
                      </div>
                    </Menu>
                  </Grid>

                  {loginPopUp ?
                    <LoginWithEmail open={open} handleClose={handleClosePopUp} handleSubmitPopUp={handleSubmitPopUp} message={dataLogin || ''} handleSubmitLoginPopUp={handleSubmitLoginPopUp} loadingLogin={loading} />
                    : null}
                  {openStocks ? <StockWithPopUpList handleCloseStocksPopUp={handleCloseStocksPopUp} /> : null}
                  {openStocks ? <StockWithPopUpList open={openStocks} handleCloseStocksPopUp={handleCloseStocksPopUp} handleStocksPopUpData={handleStocksPopUpData} /> : null}

                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>

       
      </Toolbar>

    </div>
  )
}
