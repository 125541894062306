import { useSearchParams } from "react-router-dom";
const PaymentSuccess = () => {
  const seachQuery = useSearchParams()[0];

  const referenceNum = seachQuery.get("reference");
  return (
    <>
      {/* <VStack h="100vh" justifyContent={"center"}> */}
      <h1> Order Successfull</h1>

      <h2>Reference No.{referenceNum}</h2>
      {/* </VStack> */}
    </>
  );
};

export default PaymentSuccess;
