import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch2 } from "../helpers/fetch2";
const app_url = process.env.REACT_APP_API_URL;

console.log("sunder",app_url)
interface DataObject {
  data: any;
  loading: boolean;
  error: string;
  check: number;
  msg: string;
}

const initialState: DataObject = {
  data: null,
  loading: false,
  error: "",
  check: 0,
  msg: "",
};
export const feedbackUser = createAsyncThunk(
  "postFeedback",
  async (body: any) => {
    console.log("sunder", body)
    const result = await fetch2(`${app_url}/feedback`, body);
    return result;
  }
);

const feedbackReducer = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    reset: (state: any) => {
      state.loading = false;
      state.check = 0;
      state.msg = "";
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(feedbackUser.pending, (state, action) => {
      state.check = 0;
      state.loading = false;
    });

    builder.addCase(feedbackUser.fulfilled, (state, action) => {
      state.loading = true;
      if (action.payload.status === 1) {
        state.check = 1;
        state.data =action.payload;
      } else {
        state.check = 3;
        state.data = action.payload;
      }
    });

    builder.addCase(feedbackUser.rejected, (state, action) => {
      state.check = 2;
      state.loading = false;
    
    });
  },
});
export const { reset } = feedbackReducer.actions;
export default feedbackReducer.reducer;
