import React, { useState } from 'react';
import { Button, Dialog, useTheme, Grid, IconButton, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Checkbox, TextField, Link, Box } from '@mui/material';
import { tokens } from '../theme';
import CircularProgress from '@mui/material/CircularProgress';
import logo1 from '../assets/logo1.png';
import logo from '../assets/logo.png';


import CloseIcon from '@mui/icons-material/Close';
interface FormDataReg {
  name: string;
  email: string;
  address: string;
  dob: string;
  mobile: string;
  password: string;
}

interface FormDataLogin {
  email: string;
  password: string;
}
function LoginWithEmail({ open, handleClose, handleSubmitPopUp, message, handleSubmitLoginPopUp, loadingLogin }: any) {
  const [openRegister, setOpenRegister] = useState(false);
  const [openLogin, setOpenLogin] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [errors, setErrors] = useState<Partial<FormDataLogin>>({});
  const [errorsReg, setErrorsReg] = useState<Partial<FormDataReg>>({});
  const [formDataLogin, setFormDataLogin] = useState<FormDataLogin>({
    email: '',
    password: ''
  });


  const [formData, setFormData] = useState<FormDataReg>({
    name: '',
    email: '',
    address: '',
    dob: '',
    mobile: '',
    password: '',
  });

  const handleRegister = () => {
    setOpenRegister(true);
    setOpenLogin(false);
  }

  const handleLogin = () => {
    setOpenLogin(true);
    setOpenRegister(false);
  }

  //login
  const handleChangeLogin = (event: any) => {
    const { name, value } = event.target;
    setFormDataLogin({ ...formDataLogin, [name]: value });
  };




  //register
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };



  const handleSubmitLogin = () => {
    const newErrors: Partial<FormDataLogin> = {};

    if (!formDataLogin.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formDataLogin.email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!formDataLogin.password) {
      newErrors.password = 'Password is required';
    }

    // If there are errors, set them and prevent form submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    handleSubmitLoginPopUp(formDataLogin)
  };

  const isValidDate = (dateString: string): boolean => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false; // Invalid format
    const d = new Date(dateString);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
  };
  //register
  const handleSubmit = () => {
    const newErrors: Partial<FormDataReg> = {};

    if (!formData.name) {
      newErrors.name = 'Name is required';
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!formData.address) {
      newErrors.address = 'Address is required';
    }

    if (!formData.dob) {
      newErrors.dob = 'Date of Birth is required';
    } else if (!isValidDate(formData.dob)) {
      newErrors.dob = 'Invalid date format';
    }

    if (!formData.mobile) {
      newErrors.mobile = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = 'Invalid mobile number';
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrorsReg(newErrors);
      return;
    }

    handleSubmitPopUp(formData)
  };


  return (
    <>
      {openLogin ? <Dialog open={open} maxWidth="md" fullWidth >
        <DialogTitle sx={{ backgroundColor: colors.blueAccent[900], color: colors.white[100], textAlign: 'center' }}>Login</DialogTitle>



        <DialogContent sx={{ mt: 2, mb: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  marginBottom: 10  }}>

            <img src={logo} style={{ height: '100px', width: '100px', borderRadius: '50px', border: '2px solid black' }} alt="logo" />

          </div>


          <DialogContentText >
            Please fill out the following fields to login:
          </DialogContentText>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: colors.white[100]
            }}
          >
            <CloseIcon />
          </IconButton>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={formDataLogin.email}
            onChange={handleChangeLogin}
            error={!!errors.email}
            helperText={errors.email}
          />

          <TextField
            autoFocus
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            fullWidth
            value={formDataLogin.password}
            onChange={handleChangeLogin}
            error={!!errors.password}
            helperText={errors.password}
          />
          <DialogContentText sx={{ color: colors.black[100], ml: 1, fontSize: 13 }}>
            By Signing up, you agree to our Terms of Use and Privacy Policy
          </DialogContentText>
          <DialogContentText sx={{ color: colors.redAccent[500] }}>
            {message.msg}
          </DialogContentText>
        </DialogContent>

        <DialogActions >
          <Grid container justifyContent="space-between" >
            <Grid item sx={{ backgroundColor: colors.blueAccent[900], color: colors.white[100], borderRadius: 10, mb: 1, width: '45%', textAlign: 'center', p: 0.5 }}>
              <Button onClick={handleRegister} sx={{ color: colors.white[100] }} >
                Signup
              </Button>
            </Grid>
            <Grid item sx={{ backgroundColor: colors.blueAccent[900], color: colors.white[100], borderRadius: 10, mb: 1, width: '45%', textAlign: 'center', p: 0.5 }}>

              {loadingLogin ?

                <CircularProgress sx={{ color: colors.white[100] }} size={30} />
                :
                <Button onClick={handleSubmitLogin} sx={{ color: colors.white[100] }}>
                  Login
                </Button>
              }
            </Grid>
            <Grid item sx={{ color: colors.white[100], borderRadius: 10, mb: 1, width: '100%', textAlign: 'center', p: 0.5 }}>
              <Link href="/forgetPassword" target="_blank" sx={{ color: colors.black[100] }}>
                Forgot Password?
              </Link>
            </Grid>
          </Grid>
        </DialogActions>






      </Dialog>

        : null}


      {openRegister ?
        <Dialog open={open} >
          <DialogTitle sx={{ backgroundColor: colors.blueAccent[900], color: colors.white[100], textAlign: 'center' }}>Signup</DialogTitle>
          <DialogContent sx={{ mt: 2, mb: 1 }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>

              <img src={logo} style={{ height: '100px', width: '100px', borderRadius: '50px', border: '2px solid black' }} alt="logo" />

            </div>
            <DialogContentText >
              Please fill out the following fields to sign up:
            </DialogContentText>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: colors.white[100]
              }}
            >
              <CloseIcon />
            </IconButton>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              error={!!errorsReg.name}
              helperText={errorsReg.name}
            />
            <TextField
              margin="dense"
              id="email"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              error={!!errorsReg.email}
              helperText={errorsReg.email}
            />
            <TextField
              margin="dense"
              id="address"
              name="address"
              label="Address"
              type="text"
              fullWidth
              value={formData.address}
              onChange={handleChange}
              error={!!errorsReg.address}
              helperText={errorsReg.address}
            />
            <TextField
              margin="dense"
              id="dob"
              name="dob"
              label="Date of Birth"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={formData.dob}
              onChange={handleChange}
              error={!!errorsReg.dob}
              helperText={errorsReg.dob}

            />
            <TextField
              margin="dense"
              id="mobile"
              name="mobile"
              label="Mobile Number"
              type="number"
              fullWidth
              value={formData.mobile}
              onChange={handleChange}
              error={!!errorsReg.mobile}
              helperText={errorsReg.mobile}
            />
            <TextField
              margin="dense"
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={formData.password}
              onChange={handleChange}
              error={!!errorsReg.password}
              helperText={errorsReg.password}
            />

            <DialogContentText sx={{ color: colors.black[100], ml: 1, fontSize: 13 }}>
              By Signing up, you agree to our Terms of Use and Privacy Policy
            </DialogContentText>
            <DialogContentText sx={{ color: colors.redAccent[500] }}>
              {message.msg}
            </DialogContentText>
          </DialogContent>

          <DialogActions >
            <Grid container justifyContent="space-between">

              <Grid item sx={{ backgroundColor: colors.blueAccent[900], color: colors.white[100], borderRadius: 10, mb: 1, width: '45%', textAlign: 'center', p: 0.5 }}>

                <Button onClick={handleLogin} sx={{ color: colors.white[100] }}>
                  Login
                </Button>
              </Grid>
              <Grid item sx={{ backgroundColor: colors.blueAccent[900], color: colors.white[100], borderRadius: 10, mb: 1, width: '45%', textAlign: 'center', p: 0.5 }}>
                {loadingLogin ?

                  <CircularProgress sx={{ color: colors.white[100] }} size={30} />
                  :
                  <Button onClick={handleSubmit} sx={{ color: colors.white[100] }}>
                    Signup
                  </Button>
                }
              </Grid>
              <Grid item sx={{ color: colors.white[100], borderRadius: 10, mb: 1, width: '100%', textAlign: 'center', p: 0.5 }}>
                <Link href="/forgetPassword" target="_blank" sx={{ color: colors.black[100] }}>
                  Forgot Password?
                </Link>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        : null}
    </>
  );
}

export default LoginWithEmail;