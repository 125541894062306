import { Container, Typography, Grid, Card, CardContent, CardActions, Button, CardMedia } from '@mui/material';
import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from '../../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getAllTutorials } from '../../reducers/tutorialReducer';

function Tutorials() {
    const { loading, data, check } = useSelector((state: any) => state.tutorial);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    useEffect(() => {
        dispatch(getAllTutorials())
        console.log(data.data)
    }, [])


    return (
        <>
            <Header value={'Tutorials'} />
            <Container maxWidth="md">
                <Grid container spacing={3} mt={15} mb={10}>
                    {data.data && data.data.map((post: any) => (
                        <Grid item key={post.id} xs={12} sm={6}>
                            <Card>
                                <CardMedia
                                    component="iframe"
                                    height="auto"
                                    src={post.video_link}
                                    title={post.title}
                                />
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {post.title}
                                    </Typography>
                                    <Typography variant="subtitle1" color="textSecondary">
                                    {`At ${ new Date(post.createdAt).getUTCDate().toString().padStart(2, '0')}-${(new Date(post.createdAt).getUTCMonth() + 1).toString().padStart(2, '0')}-${new Date(post.createdAt).getUTCFullYear()}`}
                                  </Typography>
                                    <Typography variant="body2" component="p">
                                        {post.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" color="primary">
                                        Read More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer />
        </>

    );
}

export default Tutorials;
