import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { Alert, Box, Card, CardActions, Grid, Typography } from "@mui/material";

const PaymentComp = () => {
  const data: any = useSelector((state: RootState) => state.auth);
  let userData = data.data;
  const [book] = React.useState({
    name: "Pay Now",
    author: "oneto1advice",
    img: "https://images-na.ssl-images-amazon.com/images/I/817tHNcyAgL.jpg",
    price: 1000,
    discount: 100,
    coupon_code: "sunder25",
  });

  const initPayment = (data: any) => {
    const options = {
      key: "rzp_test_L66KY8t9c0AiZh",
      amount: data.amount,
      currency: data.currency,
      name: book.name,
      description: "Test Transaction",
      image: book.img,
      order_id: data.id,
      prefill: {
        name: userData.username,
        email: userData.email,
        contact: "7060532399",
      },
      handler: async (response: any) => {
        try {
          const verifyUrl: any = `http://localhost:8803/api/payment/paymentverification/${userData._id}`;
          const { data }: any = await axios.post(verifyUrl, response);
          if (data) {
            var dataValue = JSON.stringify({
              total_amount: book.price,
              discount_amount: book.discount,
              grand_total: data.amount,
              coupon_code: book.coupon_code,
            });
            var dataValueup = JSON.stringify({
              free: 2,
              payment: new Date(),
            });

            try {
              const [response, details] = await Promise.all([
                (
                  await fetch(
                    `http://localhost:8803/api/payment/updatePayment/${data.value._id}`,
                    {
                      method: "put",
                      headers: { "Content-Type": "application/json" },
                      body: dataValue,
                    }
                  )
                ).json(),
                (
                  await fetch(
                    `http://localhost:8803/api/payment/updateVerification/${userData._id}`,
                    {
                      method: "put",
                      headers: { "Content-Type": "application/json" },
                      body: dataValueup,
                    }
                  )
                ).json(),
              ]);
              alert("Successfully payment done");
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  const handlePayment = async () => {
    try {
      let total_amount: any = book.price;
      let discount_amount: any = book.discount;
      const grand_total = total_amount - discount_amount;
      const orderUrl = "http://localhost:8803/api/payment/checkout";
      const { data }: any = await axios.post(orderUrl, {
        amount: grand_total,
      });
      initPayment(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1,mt: 15, ml:2, mr:2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card sx={{
                  border: '1px solid #1976d2',
                  borderRadius: 2,
                }}>
              <CardActions>
                <div style={{ flex: 0.5 }}>
                  <Typography align="left" color="black">
                    Name
                  </Typography>
                  <Typography align="left" color="black">
                    Service
                  </Typography>
                  <Typography align="left" color="black">
                    Auther
                  </Typography>
                  <Typography align="left" color="black">
                    Price
                  </Typography>
                </div>

                <div style={{ flex: 0.5, fontVariantCaps: "normal" }}>
                  <Typography align="left" color="black">
                    : Pay Now
                  </Typography>
                  <Typography align="left" color="black">
                    : (Derivative, Stocks)
                  </Typography>
                  <Typography align="left" color="black">
                    : Oneto1advice
                  </Typography>
                  <Typography align="left" color="black">
                    : &#x20B9; 1000
                  </Typography>
                </div>
              </CardActions>
              <>
                <CardActions
                  style={{
                    justifyContent: "center",
                    backgroundColor: "#1976d2",
                    alignItems: "center",
                    fontVariantCaps: "petite-caps",
                    cursor: "pointer",
                  }}
                  onClick={handlePayment}
                >
                  <Typography color="white">Pay</Typography>
                </CardActions>
              </>
            </Card>

            
          </Grid>


          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card sx={{
                  border: '1px solid #1976d2',
                  borderRadius: 2,
                }}>
              <CardActions>
                <div style={{ flex: 0.5 }}>
                  <Typography align="left" color="black">
                    Name
                  </Typography>
                  <Typography align="left" color="black">
                    Service
                  </Typography>
                  <Typography align="left" color="black">
                    Auther
                  </Typography>
                  <Typography align="left" color="black">
                    Price
                  </Typography>
                </div>

                <div style={{ flex: 0.5, fontVariantCaps: "normal" }}>
                  <Typography align="left" color="black">
                    : Pay Now
                  </Typography>
                  <Typography align="left" color="black">
                    : All
                  </Typography>
                  <Typography align="left" color="black">
                    : Oneto1advice
                  </Typography>
                  <Typography align="left" color="black">
                    : &#x20B9; 1000
                  </Typography>
                </div>
              </CardActions>
              <>
                <CardActions
                  style={{
                    justifyContent: "center",
                    backgroundColor: "#1976d2",
                    alignItems: "center",
                    fontVariantCaps: "petite-caps",
                    cursor: "pointer",
                  }}
                  onClick={handlePayment}
                >
                  <Typography color="white">Pay</Typography>
                </CardActions>
              </>
            </Card>

            
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default PaymentComp;
