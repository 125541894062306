import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme, Typography, Grid, Paper, TextField, Button, Alert } from "@mui/material";
import { tokens } from "../../theme";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Header from "../../components/HeaderHome";
import FooterHome from "../../components/FooterHome";
import backgroundImage from '../../assets/home.jpg';
import logo from '../../assets/logo.png';
import backgroundClient from '../../assets/client.png';
import backgroundTouch from '../../assets/touch.jpg';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ButtonBase from '@mui/material/ButtonBase';
import './home.css';
import { onMessageListener } from "../../services/firebase";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { ThunkDispatch } from "redux-thunk";
import { feedbackUser, reset } from "../../reducers/feedbackReducer";
function Home() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { loading, data, check } = useSelector((state: any) => state.feedback);
  const navigation = useNavigate();
  const theme = useTheme();
  const [error, setError] = useState('');
  const colors = tokens(theme.palette.mode);
  const [isHovered, setIsHovered] = useState(null);
  const [isHoveredBlog, setIsHoveredBlog] = useState(null);
  const [isHoveredChooseUs, setIsHoveredChooseUs] = useState(null);
  const [isHoveredReview, setIsHoveredReview] = useState(null);
  const [show, setShow] = useState(false);
  const [loadingdata, setLoading] = React.useState(false);
  const [name, setName] = useState(true);
  const [toastTitle, setToastTitle] = useState('');
  const [toastBody, setToastBody] = useState('');

  useEffect(() => {
    const setupMessageListener = async () => {
      try {
        const payload: any = await onMessageListener();
        setToastTitle(payload.notification.title);
        setToastBody(payload.notification.body);
        setShow(true);
      } catch (error) {
        console.error('Error listening for messages:', error);
      }
    };

    setupMessageListener();
  }, []);

  const handleClose = () => {
    setShow(false);
  };


  const section1Style = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '20px', // Adjust padding as needed
  };

  const section2Style = {
    backgroundImage: `url(${backgroundClient})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '20px', // Adjust padding as needed
  };


  const section4Style = {
    backgroundImage: `url(${backgroundTouch})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '20px', // Adjust padding as needed
  };

  const section3Style = {
    backgroundColor: "#E1D9D1",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '20px', // Adjust padding as needed

  };


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    mobile: ''
  });

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.mobile || !formData.message) {
      setError('Please fill in all the required fields.');
      return;
    }

    try {
      setLoading(true);
      let name: any = formData.name;
      let email: any = formData.email;
      let mobile: any = formData.mobile;
      let message: any = formData.message;
      dispatch(feedbackUser({ name, email, mobile, message }))
      setError('')
    } catch (error) {
      setError('An error occurred while submitting the form. Please try again later.');
      setLoading(false); // Reset loading state
    }

  };


  useEffect(() => {

    if (data && data?.status === 1) {
      alert(data.msg)
      setFormData({
        name: '',
        email: '',
        message: '',
        mobile: ''
      })
      dispatch(reset())
    }
    setLoading(false);
  }, [data])


  let tutorials = [
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment. ${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment. ${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment. ${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment. ${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment. ${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    }

  ]



  let blog = [
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    }
  ]




  let chooseUs = [
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Best Advice",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    }
  ]



  let review = [
    {
      name: "Sunder",
      rating: "4.5/5",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Shweta Tiwari",
      rating: "4.5/5",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Rohan",
      rating: "4.5/5",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Sachin Dhoval",
      rating: "4.5/5",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Dipika Sharma",
      rating: "4.5/5",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    },
    {
      name: "Pradeep Kushwaha",
      rating: "4.5/5",
      description: `Dreams don’t work until you plan carefully and balancing your money is the key to having enough investment.${<span style={{ color: colors.blueAccent[900] }}>Read More</span>}`
    }
  ]



  const handleClick = (index: any, item: any) => {
    if (item === "tutorial") {
      navigation('/tutorial')
    } else if (item === "blog") {
      navigation('/blog')
    }
  };


  const handleMouseEnter = (index: any) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const handleMouseEnterBlog = (index: any) => {
    setIsHoveredBlog(index);
  };

  const handleMouseLeaveBlog = () => {
    setIsHoveredBlog(null);
  };

  const handleMouseEnterChooseUs = (index: any) => {
    setIsHoveredChooseUs(index);
  };

  const handleMouseLeaveChooseUs = () => {
    setIsHoveredChooseUs(null);
  };


  const handleMouseEnterReview = (index: any) => {
    setIsHoveredReview(index);
  };

  const handleMouseLeaveReview = () => {
    setIsHoveredReview(null);
  };





  return (
    <div>
      <Header />

      <Grid container spacing={3} sx={{ mt: 10, mb: 2, backgroundColor: "#E1D9D1" }} >
        <Grid item xs={12}>
          <Paper style={section1Style} >
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <Typography sx={{ fontSize: 25, color: '#000', fontWeight: "bold", textAlign: 'center' }}>
                  Trusted Stock Trading Company
                </Typography>
                <Typography>
                  We are SEBI Registered and Trusted Company. We have satified arround 1000+ customer. THey are using our services. Now they are very happy.
                </Typography>
              </Grid>


              <Grid item xs={12} sm={6} md={6} >
                <Paper sx={{ p: 2, backgroundColor: "#E1D9D1" }}>
                  <Typography sx={{ fontSize: 20, color: '#000', fontWeight: "bold", textAlign: 'center', p: 2 }}>
                    Get in Touch
                  </Typography>

                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} >
                      <Grid item xs={12} >
                        <TextField
                          label="Name*"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          fullWidth

                        />

                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Email*"
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Mobile*"
                          name="mobile"
                          type="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Message*"
                          name="message"
                          multiline
                          rows={4}
                          value={formData.message}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      {error && (
                        <Grid item xs={12}>
                          <Alert severity="error">{error}</Alert>
                        </Grid>
                      )}
                      <Grid item xs={12}>

                        <LoadingButton
                          type="submit"
                          endIcon={<SendIcon />}
                          loading={loadingdata}
                          loadingPosition="end"
                          fullWidth
                          color="primary"
                          variant="contained"
                          sx={{ mt: 2, mb: 1 }}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>

                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>




        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={{ fontSize: 25, color: '#000', fontWeight: "bold", textAlign: 'center' }}>
            Wecome to SEBI Registered Market Advisory Services
            By
            Oneto1Advice.com
          </Typography>
          <Typography sx={{ fontSize: 20, color: '#000', fontWeight: "bold", textAlign: 'center' }}>
            SEBI Registration No. 000000000000
          </Typography>
          <Typography sx={{ pb: 5, pr: 5, pl: 5, pt: 1, textAlign: 'center', alignSelf: 'center', justifyContent: 'center' }}>
            Oneto1advice.com is a distinguished stock market researcher and analyst (SEBI Registration No. 000000000000), revered for expertise in decoding market trends and providing invaluable insights to investors. As the driving force behind Oneto1advice.com Research Analyst, Oneto1Advice.com spearheads a team committed to delivering comprehensive analyses and actionable strategies for navigating the complexities of the stock market.
            We are enabling scalable growth by leveraging stock market intelligence and analytics. We are exercising judicious investment aligned with risk appetite; operating effectively to deliver business outcomes. This is what makes us a complete Stock Market Advisor.
          </Typography>
        </Grid>

        {/* //third */}

        <Grid item xs={12}>
          <Paper style={section2Style} >
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 25, color: '#fff', fontFamily: 'serif', fontWeight: "bold", textAlign: 'center' }}>
                What Our Clients Say
              </Typography>
            </Grid>
            <Grid container spacing={2} direction="row" mt={2}>



              {review.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ButtonBase
                    onMouseEnter={() => handleMouseEnterReview(index)}
                    onMouseLeave={handleMouseLeaveReview}
                    className={isHoveredReview === index ? 'blur-effect' : ''}
                  >
                    <Paper sx={{ p: 2 }}>
                      <img src={logo} className="img-home" alt="logo" />
                      <Typography>
                        {item.name} <br />
                        {item.rating} <br />
                        {item.description}
                      </Typography>
                    </Paper>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>


        {/* //four */}
        <Grid item xs={12} >
          <Paper style={section3Style}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 25, color: '#000', fontWeight: "bold", textAlign: 'center' }}>
                Why Choose Us - Share Market Research Analyst
              </Typography>
            </Grid>
            <Grid container spacing={2} direction="row" mt={2}>
              {chooseUs.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ButtonBase
                    onMouseEnter={() => handleMouseEnterChooseUs(index)}
                    onMouseLeave={handleMouseLeaveChooseUs}
                    className={isHoveredChooseUs === index ? 'blur-effect' : ''}
                  >
                    <Paper sx={{ p: 2 }}>
                      <Typography sx={{ fontSize: 18, color: '#000', fontWeight: "bold", textAlign: 'center' }}>
                        {item.name}
                      </Typography>
                      <Typography sx={{ fontSize: 14, color: '#999999', textAlign: 'center' }}>
                        {item.description}
                      </Typography>
                    </Paper>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>


        {/* //blog */}
        <Grid item xs={12} >
          <Paper style={section2Style}>
            <Typography sx={{ fontSize: 25, color: '#fff', fontWeight: "bold", textAlign: 'center' }}>
              Blogs
            </Typography>
            <Grid container spacing={2} direction="row" mt={2}>
              {blog.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ButtonBase
                    onClick={() => handleClick(index, "blog")}
                    onMouseEnter={() => handleMouseEnterBlog(index)}
                    onMouseLeave={handleMouseLeaveBlog}
                    className={isHoveredBlog === index ? 'blur-effect' : ''}
                  >
                    <Paper sx={{ p: 2 }}>
                      <Typography sx={{ fontSize: 18, color: '#000', fontWeight: "bold", textAlign: 'center' }}>
                        {item.name}
                      </Typography>
                      <Typography sx={{ fontSize: 14, color: '#999999', textAlign: 'center' }}>
                        {item.description}
                      </Typography>
                    </Paper>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {show ?
          <Snackbar
            open={show}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success">
              <strong>{toastTitle}</strong><br />
              {toastBody}
            </MuiAlert>
          </Snackbar>
          : null}

        {/* //tutorial */}
        <Grid item xs={12}>
          <Paper style={section4Style}>
            <Typography sx={{ fontSize: 25, color: '#fff', fontWeight: "bold", textAlign: 'center' }}>
              Tutorials
            </Typography>
            <Grid container spacing={2} direction="row" mt={2}>
              {tutorials.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ButtonBase
                    onClick={() => handleClick(index, "tutorial")}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    className={isHovered === index ? 'blur-effect' : ''}
                  >
                    <Paper sx={{ p: 2 }}>
                      <Typography sx={{ fontSize: 18, color: '#000', fontWeight: "bold", textAlign: 'center' }}>
                        {item.name}
                      </Typography>
                      <Typography sx={{ fontSize: 14, color: '#999999', textAlign: 'center' }}>
                        {item.description}
                      </Typography>
                    </Paper>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <FooterHome />
    </div>
  );
}
export default Home;
