import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AppBar, Container, Toolbar, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import "../products/product.css";
import TabStocks from '../../components/TabStocks';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getStocksList } from '../../reducers/stockReducer';
import CircularProgress from '@mui/material/CircularProgress';

export default function Stocks() {
    const { state } = useLocation();
    const theme = useTheme();
    const [, setUnique] = useState([]);
    const [valueFno, setValueFNO] = React.useState('call/put');
    const [valueIntraday] = React.useState('daily_share');
    const [valueWeekly] = React.useState('btst');
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { loading, data } = useSelector(
        (state: any) => state.stockList
    );

    function handleChange(event: any, newValue: any) {
        const filtered = data?.data.filter((item: any) => item.sub_child.toUpperCase() === newValue.toUpperCase())
        setUnique(filtered)
        setValueFNO(newValue)
    }

    useEffect(() => {
        let token = state.token;
        let today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();
        const dateStart = new Date(getFirstDayOfMonth(currentYear, currentMonth));
        const dateEnd = new Date(getLastDayOfMonth(currentYear, currentMonth));
        const yearStart = dateStart.getFullYear();
        const monthStart = dateStart.getMonth() + 1; // Months are zero-indexed, so add 1
        const dayStart = dateStart.getDate();
        const yearEnd = dateEnd.getFullYear();
        const monthEnd = dateEnd.getMonth() + 1; // Months are zero-indexed, so add 1
        const dayEnd = dateEnd.getDate();
        let startDate = yearStart + "-" + formatMonthWithLeadingZero(monthStart) + "-" + formatMonthWithLeadingZero(dayStart);
        let endDate = yearEnd + "-" + formatMonthWithLeadingZero(monthEnd) + "-" + formatMonthWithLeadingZero(dayEnd);
        dispatch(getStocksList({ token, startDate, endDate }));
        setValueFNO(valueFno)
    }, [dispatch, valueFno]);



    function getFirstDayOfMonth(year: number, month: number) {
        return new Date(year, month, 1);
    }

    function getLastDayOfMonth(year: number, month: number) {
        return new Date(year, month + 1, 0);
    }


    function formatMonthWithLeadingZero(date: any) {
        return date < 10 ? '0' + date : date;
    }


    function CallFNO() {
        return (
            <Tabs
                value={valueFno}
                onChange={handleChange}
                variant={"standard"}
                centered
            >
                <Tab value="call/put" label="Call/Put" style={{ color: '#fff' }} sx={{
                    '&.Mui-selected': {
                        backgroundColor: colors.blueAccent[500], // Change background color when selected
                        color: 'white'
                    }
                }} />
                <Tab value="future" label="Future" style={{ color: '#fff' }} sx={{
                    '&.Mui-selected': {
                        backgroundColor: colors.blueAccent[500], // Change background color when selected
                        color: 'white'
                    }
                }} />
            </Tabs>
        )
    }


    const CallIntraday = () => {
        return (
            <Tabs
                value={valueIntraday}
                // onChange={handleChange}
                variant={"standard"}
                centered

            >
                <Tab value="daily_share" label="Intraday(Daily Share)" style={{ color: '#fff' }} sx={{
                    '&.Mui-selected': {
                        backgroundColor: colors.blueAccent[500], // Change background color when selected
                        color: 'white'
                    }
                }}
                />
            </Tabs>
        )
    }




    const CallWeekly = () => {
        return (
            <Tabs
                value={valueWeekly}
                // onChange={handleChange}
                variant={"standard"}
                centered
            >
                <Tab value="btst" label="Buy Today sell Tomorrow(BtSt)" style={{ color: '#fff' }} sx={{
                    '&.Mui-selected': {
                        backgroundColor: colors.blueAccent[500], // Change background color when selected
                        color: 'white'
                    }
                }} />
            </Tabs>
        )
    }
    return (
        <>

            <AppBar position="static">
                <Toolbar>
                    <Header value={"Stocks"} />
                </Toolbar>
            </AppBar>

            <>
                <Container>
                    <div className="bloc-tabs">
                        {state.child.text === "Future and Options" ? <CallFNO /> : state.child.text === "Intraday" ? <CallIntraday /> : <CallWeekly />}
                    </div>
                    {loading ? <CircularProgress style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }} /> :
                        <div className="content-tabs">
                            <TabStocks data={data?.data || ''} chilData={state.child.text} valueFno={valueFno} />
                        </div>
                    }
                </Container>
            </>

            <Footer />
        </>
    )
}
