import React from 'react';
import { Container, Box, Grid, useTheme, Typography, ListItemIcon, Stack, Paper } from '@mui/material';
import { tokens } from '../theme';
import play_store from '../assets/play-store.png';
import app_store from '../assets/app-store.png';
import { useNavigate } from 'react-router-dom';
const FooterHome = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const services = ['Derivative', 'Stocks', 'Bucket', 'Commodity', 'Currency'];
  const compliance = ['SEBI Registration No:', '000000000000', 'Compliance Number:', '+91 0000000000', 'Compliance Email:', 'oneto1advice@gmail.com'];
  const get_in_touch = ['Address: Plot No.9, Sector 18, Noida, UP 203131 India', 'Email: oneto1advice@gmail.com', 'Phone: +91-0000000000']
  const usefull = ['About Us', 'Blogs', 'Tutorials', 'Call', 'Chat'];

  const [hovered, setHovered] = React.useState(null);
  const navigate = useNavigate();
  const handleMouseEnter = (service: any) => {
    setHovered(service);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };


  const handleClick = (service: any) => {
    if (service === "Blogs") {
      navigate('/blog')
    } else if (service === "About Us") {
      navigate('/about')
    } else if (service === "Tutorials") {
      navigate('/tutorial')
    } else if (service === "Call") {
      navigate('/call')
    } else if (service === "privacy") {
      navigate('/privacy')
    } else {
      navigate('/service')
    }

    console.log(`${service} clicked`);
  };


  return (
    <div style={{
      backgroundColor: colors.blueAccent[900],
      color: "white",
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      fontSize: 10
    }}>
      <Container>
        {/* Row 1 */}
        <Typography sx={{ fontSize: 25, fontFamily: 'serif', fontStyle: 'bold' }}>Disclaimer:</Typography>
        <Typography sx={{ fontSize: 16, fontFamily: 'serif', fontStyle: 'bold', padding: 5 }}>Oneto1Advice.com Does Not Provide Only Any Assured Return/Garunteed Return/Profit services, any Profit Sharing Services and Services Which are not mentioned on Company Website Please Trade Only a Company SMS With Proper Stop Loss. We never ask for Demat Detail From Anyone Please ask If anyone talks about these services +91 0000000000</Typography>
        <div style={{ borderBottom: '2px solid white' }} />
        <Grid container spacing={2} sx={{ p: 5, textAlign: 'center' }}>



          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                fontSize: 20,
                fontFamily: 'serif',
                fontWeight: 'bold',
                marginTop: 1,
                textAlign: 'left',
              }}

            >
              Compliance Info
            </Typography>
            {compliance.map((compliance) => (
              <Typography
                key={compliance}
                sx={{
                  fontSize: 15,
                  fontFamily: 'serif',
                  marginTop: 1,
                  textAlign: 'left',
                  cursor: 'pointer',
                  color: hovered === compliance ? 'blue' : 'white' // Change color on hover
                }}
                onMouseEnter={() => handleMouseEnter(compliance)}
                onMouseLeave={handleMouseLeave}
              // onClick={() => handleClick(compliance)}
              >
                {compliance}
              </Typography>
            ))}
          </Grid>



          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                fontSize: 20,
                fontFamily: 'serif',
                fontWeight: 'bold',
                marginTop: 1,
                textAlign: 'left',
              }}

            >
              Our Services
            </Typography>
            {services.map((service) => (
              <Typography
                key={service}
                sx={{
                  fontSize: 15,
                  fontFamily: 'serif',
                  marginTop: 1,
                  textAlign: 'left',
                  cursor: 'pointer',
                  color: hovered === service ? 'blue' : 'white'
                }}
                onMouseEnter={() => handleMouseEnter(service)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(service)}
              >
                {service}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <Typography sx={{ fontSize: 20, fontFamily: 'serif', fontStyle: 'bold', marginTop: 1, textAlign: 'left' }}>Useful Links</Typography>
            <Typography sx={{ fontSize: 15, fontFamily: 'serif', marginTop: 1, textAlign: 'left' }}>Comming...</Typography>
            <Typography sx={{ fontSize: 15, fontFamily: 'serif', marginTop: 1, textAlign: 'left' }}>Comming...</Typography>
            <Typography sx={{ fontSize: 15, fontFamily: 'serif', marginTop: 1, textAlign: 'left' }}>Comming...</Typography>
            <Typography sx={{ fontSize: 15, fontFamily: 'serif', marginTop: 1, textAlign: 'left' }}>Comming...</Typography>
            <Typography sx={{ fontSize: 15, fontFamily: 'serif', marginTop: 1, textAlign: 'left' }}>Comming...</Typography> */}


            <Typography
              sx={{
                fontSize: 20,
                fontFamily: 'serif',
                fontWeight: 'bold',
                marginTop: 1,
                textAlign: 'left',
              }}

            >
              Useful Links
            </Typography>
            {usefull.map((usefull) => (
              <Typography
                key={usefull}
                sx={{
                  fontSize: 15,
                  fontFamily: 'serif',
                  marginTop: 1,
                  textAlign: 'left',
                  cursor: 'pointer',
                  color: hovered === usefull ? 'blue' : 'white'
                }}
                onMouseEnter={() => handleMouseEnter(usefull)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(usefull)}
              >
                {usefull}
              </Typography>
            ))}

          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                fontSize: 20,
                fontFamily: 'serif',
                fontWeight: 'bold',
                marginTop: 1,
                textAlign: 'left',
              }}
            >
              Get In Touch
            </Typography>
            {get_in_touch.map((get_in_touch) => (
              <Typography
                key={get_in_touch}
                sx={{
                  fontSize: 15,
                  fontFamily: 'serif',
                  marginTop: 1,
                  textAlign: 'left',
                  cursor: 'pointer',
                  color: hovered === get_in_touch ? 'blue' : 'white'
                }}
                onMouseEnter={() => handleMouseEnter(get_in_touch)}
                onMouseLeave={handleMouseLeave}
              // onClick={() => handleClick(get_in_touch)}
              >
                {get_in_touch}
              </Typography>
            ))}




            <Stack alignItems="center" direction="row" gap={2} mt={1}>
              <Grid item xs={6}>
                <Paper style={{ backgroundColor: '', padding: 5, cursor: 'pointer' }} elevation={3} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.oneto1advice', '_blank')}>
                  <img src={play_store} alt="Play Store" style={{ maxWidth: '15%', height: 'auto', }} />
                  <Typography variant="body1">Play Store</Typography>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <Paper style={{ backgroundColor: '', padding: 5, cursor: 'pointer' }} elevation={3}>
                  <img src={app_store} alt="Play Store" style={{ maxWidth: '15%', height: 'auto', }} />
                  <Typography variant="body1">App Store</Typography>
                </Paper>
              </Grid>

            </Stack>

          </Grid>
        </Grid>
        <div style={{ borderBottom: '2px solid white', marginTop: 10, marginBottom: 20 }} />
        <Grid container spacing={2} alignItems="center" justifyContent={'space-between'}>
          <Grid item>
            <Typography sx={{ fontSize: 15, fontFamily: 'serif', textAlign: 'left', color: hovered === 'reserved' ? 'blue' : 'white' }}
              onMouseEnter={() => handleMouseEnter('reserved')}
              onMouseLeave={handleMouseLeave}>© 2024 Oneto1advice.com. All Rights Reserved.</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: 15, fontFamily: 'serif', cursor: 'pointer', textAlign: 'right', color: hovered === 'privacy' ? 'blue' : 'white'  }}
             onMouseEnter={() => handleMouseEnter('privacy')}
             onMouseLeave={handleMouseLeave}
             onClick={() => handleClick('privacy')}
            >Disclaimer |  Terms & Conditions | Privacy Policy </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FooterHome;