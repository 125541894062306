import React from 'react'
import Header from '../../components/Header'
import PaymentComp from '../../components/PaymentComp'
import Footer from '../../components/Footer'

export default function Recharge() {
  return (
    <div>
      <Header value={'Pay Now'}/>
      <PaymentComp />
      <Footer/>
    </div>
  )
}
