import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetch2, fetch33 } from "../helpers/fetch2";
const app_url = process.env.REACT_APP_API_URL;


interface DataObject {
  data: any;
  loading: boolean;
  error: string;
  check: number;
  msg: string;
};

const initialState: DataObject = {
  data: null,
  loading: false,
  error: "",
  check: 0,
  msg: "hi",
};
export const signupUser = createAsyncThunk("register", async (body: any) => {
  const result = await fetch2(`${app_url}/auth/register`, body);
  return result;
});

export const signinUser = createAsyncThunk("login", async (body: any) => {
  const result = await fetch2(`${app_url}/auth/login`, body);
  return result;
});

export const link = createAsyncThunk("link", async (body: any) => {
  const result = await fetch2(`${app_url}/password-reset`, body);
  return result;
});
export const getAllUsers = createAsyncThunk("allUsers", async () => {
  const result = await fetch33(`${app_url}/users`, "get");
  return result;
});

export const resetPassword = createAsyncThunk(
  "resetpassword",
  async (body: any) => {
    const result = await fetch2(
      `${app_url}/password-reset/${encodeURIComponent(body.userId)}/${encodeURIComponent(
        body.token
      )}`,
      body
    );
    return result;;
  }
);

const authReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    addId: (state: any) => {
      state._id = localStorage.getItem("_id");
    },
    clearState: (state: any) => {
      state.loading = false;
    },
    logout: (state: any) => {
      state.loading = false;
      state.check = 0;
      state.msg = "";
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signupUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(signupUser.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.status === 1) {
        state.check = 1;
        state.data =action.payload;
      } else {
        state.check = 0;
        state.data =action.payload;
      }
    });

    builder.addCase(signupUser.rejected, (state, action) => {
      state.loading = false;
    });
    //sign in
    builder.addCase(signinUser.pending, (state, action) => {
      state.check = 0;
      state.loading = true;
    });

    builder.addCase(signinUser.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.status === 1) {
        state.check = 1;
        state.data = action.payload;
      } else {
        state.check = 0;
        state.data = action.payload;
      }
    });

    builder.addCase(signinUser.rejected, (state, action) => {
      state.check = 2;
      state.loading = false;
    });

    builder.addCase(link.pending, (state, action) => {
      state.check = 0;
      state.loading = true;
    });

    builder.addCase(link.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.status === 1) {
        state.check = 1;
        state.data = action.payload
      } else {
        state.check = 3;
        state.data = action.payload
      }
    });

    builder.addCase(link.rejected, (state, action) => {
      state.check = 2;

      state.loading = false;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { addId, clearState, logout } = authReducer.actions;

export default authReducer.reducer;
