import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { tokens } from '../theme';
import CloseIcon from '@mui/icons-material/Close';

interface ListItemData {
    id: number;
    text: string;
}

function StockWithPopUpList({open,handleCloseStocksPopUp, handleStocksPopUpData}: any) {
    
    const [selectedItem, setSelectedItem] = useState<ListItemData | null>(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);



    const handleItemClick = (item: ListItemData) => {
        setSelectedItem(item);
        handleStocksPopUpData(item)
        handleCloseStocksPopUp();
    };

   

    // Sample list data
    const listData: ListItemData[] = [
        { id: 1, text: 'Future and Options' },
        { id: 2, text: 'Intraday' },
        { id: 3, text: 'Weekly' }
    ];

    return (

        <Dialog open={open} maxWidth="xs" fullWidth>
            <DialogTitle sx={{backgroundColor: colors.blueAccent[900], color: colors.white[100]}}>
                Select Stocks Item
                <IconButton aria-label="close" onClick={handleCloseStocksPopUp} sx={{ position: 'absolute', right: 8, top: 8, color: colors.white[100] }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>

                <List>
                    {listData.map((item, index) => (
                        <React.Fragment key={item.id}>
                        <ListItem disablePadding>
                            <ListItemButton
                                selected={selectedItem?.id === item.id}
                                onClick={() => handleItemClick(item)}
                            >
                                <ListItemText primary={item.text} />
                            </ListItemButton>
                        </ListItem>
                        {index !== listData.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
           
        </Dialog>

    );
}

export default StockWithPopUpList;