import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const requestPermission = async () => {
  const data = await Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            return currentToken;
          } else {
            return "no token";
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
  return data;

};

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    try {
      onMessage(messaging, (payload) => {
        console.log("onMessageListener initialized", payload);
         
        resolve(payload);
      });
    } catch (error) {
      // Add error handling
      console.error("Error in onMessageListener:", error);
      reject(error);
    }
  });

