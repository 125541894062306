import { Typography, useTheme,Menu, MenuItem, AppBar, IconButton, Toolbar,Grid } from '@mui/material'
import React, {useState} from 'react'
import { tokens } from '../theme';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import MotionTextLoop from './MotionText ';
import MenuIcon from '@mui/icons-material/Menu';
import logoImage from '../assets/friends.png';
export default function Header({ value }: any) {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const goBack = (value: any) => {
    if(value === "Blogs"){
      navigate('/')
    }else if(value === "Tutorials"){
      navigate('/')
    }else if(value === "About Us"){
      navigate('/')
    }else if(value === "Privacy & Policy"){
      navigate('/')
    }else if(value === "Profile"){
      navigate('/')
    }else if(value === "Call"){
      navigate('/')
    }else {
      navigate('/service')
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedItem, setClickedItem] = useState('');
  const [isTouched, setIsTouched] = useState(false);


  return (
   
    <div style={{
      backgroundColor: colors.blueAccent[900],
      color: "white",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 99,
      position: 'fixed',
    }}>

      <div style={{
        backgroundColor: colors.redAccent[900],
        color: "white",
        left: 0,
        right: 0,
      }}>
        <MotionTextLoop text="Stock Markets are Subject to market risk. No Guaranteed Returns in Stock Market." speed={25} />
      </div>
      <Toolbar>

        {value === "Welcome to Oneto1Advice" ?
          <><Typography variant="h6" sx={{ flexGrow: 1 }}>
            {value}
          </Typography></>
          : value === "Forget Password" ? <>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {value}
            </Typography></> :
            value === "Reset Password" ? <>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {value}
              </Typography></> :
              <><IconButton edge="start" color="inherit" aria-label="back" onClick={() => goBack(value)}>
                <ArrowBack />
              </IconButton><Typography variant="h6" sx={{ flexGrow: 1 }}>
                  {value}
                </Typography></>
        }
      </Toolbar>
    </div>
  )
}
