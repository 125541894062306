import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { feedbackUser } from "../reducers/feedbackReducer";
import { RootState } from "../app/store";

const FeedbackDialog = (props: any) => {
  const [nameTextInput, setNameTextInput] = React.useState("");
  const [emailTextInput, setEmailTextInput] = React.useState("");
  const [mobileTextInput, setMobileTextInput] = React.useState("");
  const [open, setOpen] = useState(props.open);
  const [messageTextInput, setMessageTextInput] = React.useState("");
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { isFetching, isSuccess, isError, errorMessage, data } = useSelector(
    (state: any) => state.feedback
  );

  const { data: dataUser } = useSelector((state: RootState) => state.auth);

  function handleNameTextInputChange(e: any) {
    setNameTextInput(e.target.value);
  }
  function handleEmailTextInputChange(e: any) {
    setEmailTextInput(e.target.value);
  }

  function handleMobileTextInputChange(e: any) {
    setMobileTextInput(e.target.value);
  }

  function handleMessageTextInputChange(e: any) {
    setMessageTextInput(e.target.value);
  }

  const handleSubmit = () => {
    let name = nameTextInput;
    let email = emailTextInput;
    let mobile = mobileTextInput;
    let message = messageTextInput;
    let userId = dataUser._id;
    setOpen(false);
    dispatch(feedbackUser({ name, email, mobile, message, userId }));
  };

  useEffect(() => {
    if (isFetching) alert(errorMessage);
  }, [isFetching, errorMessage]);

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Dialog open={open} onClick={(e) => e.stopPropagation()}>
        <DialogTitle>Feedback?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you are facing any problem. Please write here your feedback....
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={nameTextInput}
            onChange={handleNameTextInputChange}
          />
          {!nameTextInput && <Alert severity="error">Enter name</Alert>}

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={emailTextInput}
            onChange={handleEmailTextInputChange}
          />
          {!emailTextInput && <Alert severity="error">Enter email</Alert>}

          <TextField
            autoFocus
            margin="dense"
            id="mobile"
            label="Mobile"
            type="number"
            fullWidth
            variant="standard"
            value={mobileTextInput}
            onChange={handleMobileTextInputChange}
          />
          {!mobileTextInput && <Alert severity="error">Enter mobile</Alert>}

          <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            variant="standard"
            value={messageTextInput}
            onChange={handleMessageTextInputChange}
          />
          {!messageTextInput && <Alert severity="error">Enter message</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeedbackDialog;
