import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/authReducer";
import usersReducer from "../reducers/usersReducer";
import followReducer from "../reducers/followReducer";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import paymentReducer from "../reducers/paymentReducer";
import feedbackReducer from "../reducers/feedbackReducer";
import lossProfitReducer from "../reducers/lossProfitReducer";
import CryptoJS from 'crypto-js';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import stockReducer from "../reducers/stockReducer";
import blogReducer from "../reducers/blogReducer";
import tutorialReducer from "../reducers/tutorialReducer";
const reducers = combineReducers({
  auth: authReducer,
  userlist: usersReducer,
  follow: followReducer,
  payment: paymentReducer,
  feedback: feedbackReducer,
  loss_profit: lossProfitReducer,
  stockList: stockReducer,
  blog: blogReducer,
  tutorial: tutorialReducer,
});


const encryptTransform = {
  in: (state: any) => {
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(state), `${process.env.REACT_APP_SECRET_KEY}`).toString();
    return cipherText;
  },
  out: (cipherText: any) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, `${process.env.REACT_APP_SECRET_KEY}`);
    const decryptedState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedState;
  }
};
const persistConfig = {
  timeout: 50,
  key: "root",
  version: 2,
  storage,
  transforms: [encryptTransform], // Apply encryption and decryption transforms
  whitelist: ['auth', 'userlist', 'follow', 'payment', 'feedback', 'loss_profit', 'stockList'] // List of reducers to persist

};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const store = createStore(persistedReducer);
let persistor = persistStore(store);

export { store, persistor };

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
