import * as React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Grid,
  Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import io from "socket.io-client";
const Chats = () => {
  const [socket, setSocket] = React.useState<any>(null);
  const [message, setMessage] = React.useState<any>("");
  const [messagess, setMessagess] = React.useState<any>({
    messagess: [],
    receiverId: "",
    conversationId: "",
  });

  React.useEffect(() => {
    setSocket(io("http://localhost:8802"));
  }, []);

  React.useEffect(() => {
    socket?.emit("addUser", "649d2a320e425c5782e84b07");
    socket?.on("getUsers", (users: any) => {
      console.log("activeUsers :>> ", users);
    });
    socket?.on("getMessage", (data: any) => {
      setMessagess((prev: any) => ({
        ...prev,
        messagess: [
          ...prev.messagess,
          { user: data.user, message: data.message },
        ],
      }));
    });
  }, [socket]);

  // React.useEffect(() => {
  //   const fetchConversations = async () => {
  //     const res = await fetch(
  //       "http://localhost:8802/api/users/conversations/649d2a320e425c5782e84b07",
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           token:
  //             "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0OWQyYTMyMGU0MjVjNTc4MmU4NGIwNyIsImlzQWRtaW4iOmZhbHNlLCJpYXQiOjE2ODg0ODI5MjYsImV4cCI6MTY4ODkxNDkyNn0.Rd03nj2q7eaLXDIPcHXfjZMGYmGDK9PkJC0TVisNsL8",
  //         },
  //       }
  //     );
  //     const resData = await res.json();
  //     setConversations(resData);
  //   };
  //   fetchConversations();
  // }, []);

  React.useEffect(() => {
    const fetchMessage = async () => {
      const res = await fetch(
        `http://localhost:8802/api/users/messages/64a5c396f997aff20fe048a0?senderId=649d2a320e425c5782e84b07&&receiverId=649f31fe7518ba5e812d93d7`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0OWQyYTMyMGU0MjVjNTc4MmU4NGIwNyIsImlzQWRtaW4iOmZhbHNlLCJpYXQiOjE2ODg0ODI5MjYsImV4cCI6MTY4ODkxNDkyNn0.Rd03nj2q7eaLXDIPcHXfjZMGYmGDK9PkJC0TVisNsL8",
          },
        }
      );
      const resData = await res.json();
      let conversationId = "64a5c396f997aff20fe048a0";
      let receiverId = "649f31fe7518ba5e812d93d7";
      setMessagess({ messagess: resData, receiverId, conversationId });
    };
    fetchMessage();
  }, []);

  const handleSend = async () => {
    if (message.trim() !== "") {
      setMessage("");
      socket?.emit("sendMessage", {
        conversationId: messagess?.conversationId,
        senderId: "649d2a320e425c5782e84b07",
        message: message.trim(),
        receiverId: messagess?.receiverId,
      });
      await fetch(`http://localhost:8802/api/users/messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0OWQyYTMyMGU0MjVjNTc4MmU4NGIwNyIsImlzQWRtaW4iOmZhbHNlLCJpYXQiOjE2ODg0ODI5MjYsImV4cCI6MTY4ODkxNDkyNn0.Rd03nj2q7eaLXDIPcHXfjZMGYmGDK9PkJC0TVisNsL8",
        },
        body: JSON.stringify({
          conversationId: messagess?.conversationId,
          senderId: "649d2a320e425c5782e84b07",
          message: message.trim(),
          receiverId: messagess?.receiverId,
        }),
      });
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        bgcolor: "grey.200",
      }}
    >
      <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
        {messagess.messagess.length > 0 ? (
          messagess?.messagess?.map(({ message, user, index }: any) => (
            <Message message={message} user={user.id} />
          ))
        ) : (
          <Typography variant="body1">No Conversation</Typography>
        )}
      </Box>
      <Box sx={{ p: 2, backgroundColor: "background.default" }}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              size="small"
              fullWidth
              placeholder="Type a message"
              variant="outlined"
              value={message}
              onChange={(event) => {
                const { value } = event.target;
                setMessage(value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleSend}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const Message = ({ message, user }: any) => {
  const isBot = user !== "649d2a320e425c5782e84b07";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row" : "row-reverse",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ bgcolor: isBot ? "primary.main" : "secondary.main" }}>
          {isBot ? "B" : "U"}
        </Avatar>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            ml: isBot ? 1 : 0,
            mr: isBot ? 0 : 1,
            backgroundColor: isBot ? "primary.light" : "secondary.light",
            borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
          }}
        >
          <Typography variant="body1">{message}</Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default Chats;
