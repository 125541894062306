import React from 'react';
import { Container, Typography, Grid } from '@mui/material';import Header from "../../components/Header";
import Footer from '../../components/Footer';

function Privacy() {
  return (
    <>
    <Header value={'Privacy & Policy'}/>
    <Container maxWidth="md">
                <Grid container spacing={3} mt={15} mb={10}>
                    <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant="body2" style={{ textAlign: 'justify' }}>
                                    <h4>Terms and Conditions:</h4>
                                     The Oneto1advice Pvt. Ltd. Is a SEBI registered Investment analyst providing financial aids to its members on a subscription basis.
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>Registration no:</h4>
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>About services:</h4> 
                                    <li>The information provided by Oneto1advice Pvt. Ltd on its various platforms are solely for educational purpose only. You are solely responsible for the investment decision you make or any use of the services provided by us. You are not permitted to modify or transfer these information,in whole or in part, to any third party without the express written consent of Oneto1advice Pvt.Ltd. You are not allowed to alter, obscure, or remove any trademark, copyright or any other information provided.</li>
                                    <li>The Oneto1advice Pvt.Ltd. expressly disclaims any implied warranty of any types of loss incurred by you by using the information provided by us.</li>
                                    <li>The Site publishes information relating to financial and investment issues from time to time. All information published and disseminated on the Site including the recommendations, blogs, contents of our newsletters, calculators, other tools and features provided on the Site are for information and educational purposes to enable the User(s) to make prudent financial decisions.</li>
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                <p>By agreeing with our terms & conditions you allow us to fetch and access your credit information from credit information companies in order to understand your creditworthiness, for carrying our internal evaluation, as may be required for product/service offerings.</p>
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>Personal information:</h4> 
                                    <li>Personal information which could reasonably be used as your identity, such as(your name, email address, registration account details, physical address, bank account details, credit/debit card or other mode of payment instrument details). The information provided by you is given with your consent and are supposed to be true fair. Your personal information will be kept confidential to the maximum possible extent.</li>
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>Refund policy:</h4> 
                                    <li>No refunds on subscriptions are allowed which had already been taken.</li>
                                    <li>You are expected to read all the information related products & services you are availing before making any payment.</li>
                                    <li>Before making any payment you are advised to read all the information carefully including terms and conditions.</li>
                                    <p>The Oneto1advice Pvt.Ltd. shall not be liable for any failure or error of any transaction on the platform or for any failure on part of the company to perform any of its obligations.</p> 
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>NO LIABILITY FOR THE USE OF INFORMATION:</h4>
                                    <li>The Oneto1advice Pvt.Ltd. have no liability or otherwise to you or to third parties or any responsible concern for the failure of any connection or communication service to provide or maintain your access to this service without any interruption.</li> 
                                    <li>The Oneto1advice Pvt.Ltd. have no liability or otherwise to you or to third parties or any responsible concern for the correctness, quality, accuracy, timeliness, reliability, performance, continuity, completeness or delays, omission or interruptions in the delivery of the data and service available on website or on various platform of us.</li>
                                    <li>The Oneto1advice Pvt.Ltd. have no liability for your loss occurred due to any cyber attacks or security concern on your device, as a result any network, system capacity limitations, and other problems, you may, at times,experience difficulty accessing the website or communicating with us through internet or other electronic or wireless devices shall not be entertained.</li>
                                    <p style={{marginTop: 5}}>You are not allowed to use the information provided by us in any way that is against the guidelines of SEBI or any laws in India or outside India. </p>
                                    <p style={{marginTop: 5}}>The Oneto1advice Pvt. Ltd. is not responsible for the availability of content or other service on third part sites linked from the platform any liability you occur with third party is not entertained by us, you are solely responsible for any such kind of loss.</p>
                                    <p style={{marginTop: 5}}>You are solely responsible for maintaining the confidentiality of the account information  and are fully responsible for all the activities that occur in your account  and also agree to keep your account login credentials safe and confidential at all the times.</p>
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>Links To Third Party Sites:</h4>
                                    <p>1. The Oneto1advice Pvt.Ltd. states that the online resources that can be accessed from the Site by use of hypertext links are not maintained or controlled by it and therefore we not responsible for the content of such linked resources.</p> 
                                    <p>2. The Oneto1advice Pvt.Ltd. is not aware of and does not warrant the terms of use,cookies, security and privacy policies of such third party sites. The User alone is responsible to review such other sites for policies and practices that may concern him/her.</p>
                                    <p>3. The Oneto1advice Pvt.Ltd. makes no warranty express or implied with respect to the use of the hypertext links that can be accessed from the Site nor does it guarantee the accuracy, usability or adequacy of any resource, information, system, product or process available at or from such third party sites.</p>
                                    <h4 style={{marginTop: 8}}> Termination of 	Account-</h4>
                                    <li>You acknowledge and agree that The Oneto1advice Pvt.Ltd. may, in its sole discretion, cancel, suspend or terminate your account if you breach these Terms and Conditions. The Oneto1advice Pvt.Ltd. may refuse, in its sole discretion, to permit any use of the Site, and may refuse to permit any person to access or use the Site or your account for any reason at any time.</li>
                                </Typography>


                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>Research Analyst Disclaimer:</h4>
                                    <h4>Term of Use:</h4>
                                    <p> The oneto1advice Pvt.Ltd. is regulated by the Securities and Exchange Board of India (“SEBI”) and is licensed to carry on the business of broking, depository services and related activities. The business of  and its Associates The Oneto1advice Pvt.Ltd.  (list available on www. The Oneto1advice Pvt.Ltd.com) are organized in business of – Commodities, Financial Markets and Merchant Banking.</p>
                                    <p style={{marginTop: 8}}>This Report has been prepared by The Oneto1advice Pvt.Ltd. in the capacity of a Research Analyst having SEBI Registration No. ______________ and distributed as per SEBI (Research Analysts) Regulations 2014. You agree and understand that the information and material contained in this website implies and constitutes your consent to the terms and conditions mentioned below. The content of the site and the interpretation of data are solely the personal views of the contributors. </p>
                                    <p style={{marginTop: 8}}>The Oneto1advice Pvt.Ltd.  reserves the right to make modifications and alterations to the content of the website. Users are advised to use the data for the purpose of information only and rely on their own judgment while making investment decisions. The investments discussed or recommended may not be suitable for all investors. Trading is inherently risky and you agree to assume complete and full responsibility for the outcomes of all trading decisions that you make, including but not limited to loss of capital. </p>
                                    <p style={{marginTop: 8}}>None of the trading calls given by The Oneto1advice Pvt.Ltd. should be construed as an offer to buy or sell any financial instruments, nor advice to do so. All comments and posts made by The Oneto1advice Pvt.Ltd.  and employees/owners are for information purposes only and under no circumstances should be used for actual trading. Under no circumstance s should any person make trading decisions based solely on the information discussed herein. </p>
                                    <p  style={{marginTop: 8}}>We and our licensors, suppliers, vendors, affiliates, officers, agents and employees make no warranty that:</p>
                                    <p>a. The Service will meet your requirements;</p>
                                    <p>b. The Service will be uninterrupted, timely, secure, or error-free;</p>
                                    <p>c. The Service will be accessible at any time or at all times via the channel selected or used by you</p>
                                    <p>d. The information, content or advertisements contained on, distributed through, or linked, downloaded or accessed from or through the Service, or the results that may be obtained from the use of the Service, will be accurate or reliable.</p>
                                    <p>e. This disclaimer does not apply to any product warranty offered by the manufacturer of the product as specified in the product specifications. This disclaimer constitutes an essential part of the Terms and Conditions.</p>
                               
                                </Typography>
                          
                    </Grid>
                   
                </Grid>
            </Container>
            <Footer/>
    </>
   
  );
}

export default Privacy;
