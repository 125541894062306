import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch33 } from "../helpers/fetch2";
const app_url = process.env.REACT_APP_API_URL;
interface DataObject {
  data: any;
  check: number;
  loading: boolean;
  error: string;
}

// export interface DataArray {
//   productId: string;
//   loss_profit: string;
//   createdAt: string;
// }

// type dataState = {
//   data: DataObject;
//   loading: boolean;
//   error: string;
// };
const initialState: DataObject = {
  data: null,
  loading: false,
  error: "",
  check: 0,
};
export const lossProfitProduct = createAsyncThunk("lossProfit", async () => {
  const result = await fetch33(`${app_url}/product/loss_profit`, "get");
  return result;
});

const lossProfitReducer = createSlice({
  name: "loss_profit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(lossProfitProduct.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(lossProfitProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data =action.payload;
    });

    builder.addCase(lossProfitProduct.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default lossProfitReducer.reducer;
