import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch3, fetch1, fetchSpecialProduct } from "../helpers/fetch2";
const app_url = process.env.REACT_APP_API_URL;

interface DataObject{
  data: any;
  check: number;
  loading: boolean;
  error: string;
}

const initialState: DataObject = {
  data: null,
  loading: false,
  error: "",
  check: 0,
};

export const getNearUser = createAsyncThunk(
  "nearuserlist",
  async (body: any) => {
    const result = await fetch1(
      `${app_url}/users/nearby/${body._id}`,
      body.token,
      body.lat,
      body.lng
    );
    return result;
  }
);

export const getParents = createAsyncThunk("parentlist", async (body: any) => {
  const result = await fetch3(`${app_url}/product/getParent`, "get", body.token);
  return result;
});

export const getProductsList = createAsyncThunk(
  "productslistData",
  async (body: any) => {
    const result = await fetchSpecialProduct(`${app_url}/product/getAllProducts`, "POST",  body);
    return result;
  }
);


const userReducer = createSlice({
  name: "userlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getParents.pending, (state: any, action: any)  => {
      state.loading = true;
    });
    builder.addCase(getParents.fulfilled, (state, action) => {
      state.loading = false;
      state.data =action.payload;
    });
    builder.addCase(getParents.rejected, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getNearUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNearUser.fulfilled, (state, action) => {
      state.loading = false;
      state.data =action.payload;
    });
    builder.addCase(getNearUser.rejected, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getProductsList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getProductsList.fulfilled, (state, action) => {
      state.loading = false;
      state.data =action.payload;
    });
    builder.addCase(getProductsList.rejected, (state, action) => {
      state.loading = true;
    });
  },
});

export default userReducer.reducer;
