import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Chats from "./pages/chat/Chats";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import Products from "./pages/products/Products";
import PaymentSuccess from "./pages/PaymentSuccess";
import Blogs from "./pages/blogs/Blogs";
import Privacy from "./pages/privacy/Privacy";
import Tutorials from "./pages/tutorials/Tutorials";
import Contact from "./pages/contact/Contact";
import Recharge from "./pages/recharge/Recharge";
import About from "./pages/about/About";
import Profile from "./pages/Profile/Profile";
import ForgotPasswordPage from "./components/ForgetPassword";
import Stocks from "./pages/stocks/Stocks";
import Service from "./pages/services/Service";
import Call from "./pages/call/Call";
//https://medium.com/simform-engineering/firebase-cloud-messaging-in-react-a-comprehensive-guide-b5e325452f97
//https://blog.logrocket.com/push-notifications-react-firebase/
//https://stackoverflow.com/questions/48378337/create-react-app-not-picking-up-env-files
//https://firebase.google.com/docs/cloud-messaging/js/first-message
function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/chat" element={<Chats />} />
        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/products" element={<Products />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/tutorial" element={<Tutorials />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/recharge" element={<Recharge />} />
        <Route path="/about" element={<About />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/call" element={<Call />} />
        <Route path="/forgetPassword" element={<ForgotPasswordPage />} />
        <Route
          path="/password-reset/:userId"
          element={<ResetPassword />}
        />
         <Route path="/stocks" element={<Stocks />} />
      </Routes>
    </div>
  );
}

export default App;
