export const ServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then(function (registration) {
          console.log('Service Worker registered with scope:', registration);
        })
        .catch(function (err) {
          return err;
        });
    }
  };