import { Container, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import React from "react";
import Header from "../../components/Header";
import Footer from '../../components/Footer';
type TutorialPost = {
    id: number;
    title: string;
    phone: string;
    content: string;
  };
function Call() {
    const [blogPosts, setBlogPosts] = React.useState<TutorialPost[]>([]);

    React.useEffect(() => {
        const fetchedBlogPosts = [
            {
                id: 1,
                title: 'Tech Team?',
                phone: '+919999911111',
                content: 'If you have tech related query.',
            },
            {
                id: 2,
                title: 'Marketing Team',
                phone: '+919999911111',
                content: 'If you have marketing related query.',
            },
            {
                id: 3,
                title: 'Stocks Team?',
                phone: '+919999911111',
                content: 'If you have stocks related query.',
            },
            {
                id: 4,
                title: 'Job Team?',
                phone: '+919999911111',
                content: 'If you have job related query.',
            },
            {
                id: 5,
                title: 'Fund Team?',
                phone: '+919999911111',
                content: 'If you have fund related query.',
            },
            {
                id: 6,
                title: 'Other Team?',
                phone: '+919999911111',
                content: 'If you have other query.',
            }
        ];
        setBlogPosts(fetchedBlogPosts);
    }, []);
  
    return (
      <>
      <Header value={'Call'}/>
      <Container maxWidth="md">
              <Grid container spacing={3} mt={15}  mb={10}>
                  {blogPosts.map(post => (
                      <Grid item key={post.id} xs={12} sm={6}>
                          <Card>
                              <CardContent>
                                  <Typography variant="h5" component="h2">
                                      {post.title}
                                  </Typography>
                                  <Typography variant="subtitle1" color="textSecondary">
                                      {`Phone. ${post.phone}`}
                                  </Typography>
                                  <Typography variant="body2" component="p">
                                      {post.content}
                                  </Typography>
                              </CardContent>
                             
                          </Card>
                      </Grid>
                  ))}
              </Grid>
          </Container>
          <Footer/>
      </>
     
    );
}

export default Call;
