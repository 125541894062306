import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {Typography, useTheme } from '@mui/material';
import { tokens } from '../theme';
import '../../src/components/motion.css'
const MotionTextLoop = ({ text, speed }: any) => {
    const [loop, setLoop] = useState(true);
    const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    useEffect(() => {
      const timeout = setTimeout(() => {
        setLoop(false); // Stops the loop after one iteration
      }, speed * text.length * 50); // Calculate duration based on text length and speed
  
      return () => clearTimeout(timeout); // Cleanup on unmount
    }, [text, speed]);
  
    return (

        <motion.div
          initial={{ x: '100%' }}
          animate={loop ? { x: '-100%' } : { x: '100%' }} // Animate to the opposite side
          transition={{
            duration: speed,
            ease: 'linear',
            repeat: Infinity, // Infinite loop
          }}
        >
          <Typography  sx={{ fontSize: '0.8rem', p:1}}>{text}</Typography>
        </motion.div>
    );
  };
  
  export default MotionTextLoop;