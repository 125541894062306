import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSpecialProduct } from "../helpers/fetch2";
const app_url = process.env.REACT_APP_API_URL;
// export interface DataObject {
//   data: DataArray[];
//   status: number;
// }

// export interface DataArray {
//   username: string;
// }

// type dataState = {
//   data: DataObject;
//   loading: boolean;
//   error: string;
// };

// const initialState: dataState = {
//   data: {
//     data: [],
//     status: 0,
//   },
//   loading: false,
//   error: "",
// };
interface DataObject{
  data: any;
  check: number;
  loading: boolean;
  error: string;
}

const initialState: DataObject = {
  data: null,
  loading: false,
  error: "",
  check: 0,
};





export const getStocksList = createAsyncThunk(
  "stockslistData",
  async (body: any) => {

    const result = await fetchSpecialProduct(`${app_url}/stock/getAllStocks`, "Post",  body);
    return result;
  }
);


const stockReducer = createSlice({
  name: "stocklist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStocksList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStocksList.fulfilled, (state, action) => {
      state.loading = false;
      state.data =action.payload;
    });
    builder.addCase(getStocksList.rejected, (state, action) => {
      state.loading = true;
    });
  },
});

export default stockReducer.reducer;
