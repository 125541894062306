import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import Tab from "../../components/Tab";
import "./product.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { getProductsList } from "../../reducers/usersReducer";
import Header from "../../components/Header";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import TabData from "../../components/Tab";
import CircularProgress from '@mui/material/CircularProgress';

function Products() {
  const theme = useTheme();
  const [value, setValue] = React.useState('midcpnifty');
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const colors = tokens(theme.palette.mode);
  const { check, loading, data, error } = useSelector(
    (state: any) => state.userlist
  );
  const [unique, setUnique] = useState([]);
  let [childData] = React.useState('midcpnifty');
  const { state } = useLocation();
  useEffect(() => {
    let token = state.token;
    let today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const dateStart = new Date(getFirstDayOfMonth(currentYear, currentMonth));
    const dateEnd = new Date(getLastDayOfMonth(currentYear, currentMonth));
    const yearStart = dateStart.getFullYear();
    const monthStart = dateStart.getMonth() + 1; // Months are zero-indexed, so add 1
    const dayStart = dateStart.getDate();
    const yearEnd = dateEnd.getFullYear();
    const monthEnd = dateEnd.getMonth() + 1; // Months are zero-indexed, so add 1
    const dayEnd = dateEnd.getDate();
    let startDate = yearStart + "-" + formatMonthWithLeadingZero(monthStart) + "-" + formatMonthWithLeadingZero(dayStart);
    let endDate = yearEnd + "-" + formatMonthWithLeadingZero(monthEnd) + "-" + formatMonthWithLeadingZero(dayEnd);
    dispatch(getProductsList({ token, startDate, endDate }));
    setValue(childData);
  }, []);


  function getFirstDayOfMonth(year: number, month: number) {
    return new Date(year, month, 1);
  }

  function getLastDayOfMonth(year: number, month: number) {
    return new Date(year, month + 1, 0);
  }

  // Function to get Indian time
  function getIndianTime() {
    // Create a new Date object
    let date = new Date();
    date.setHours(0, 0, 0, 0);

    // UTC time + 5 hours 30 minutes to convert to Indian Standard Time
    let indianTime = new Date(date.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
    return indianTime;
  }


  // Function to format the date with leading zeros
  function formatDateWithLeadingZero(date: any) {
    return date < 10 ? '0' + date : date;
  }

  function formatMonthWithLeadingZero(date: any) {
    return date < 10 ? '0' + date : date;
  }
  // Function to get the previous Tuesday
  //for midcpnifty
  function getPreviousTuesday() {
    let today = getIndianTime().getDay(); // Get the current day
    let tuesdayOffset = (today - 2 + 7) % 7; // Calculate the offset to get to the previous Tuesday

    let previousTuesday = new Date(getIndianTime().getTime() - tuesdayOffset * 24 * 60 * 60 * 1000);
    return previousTuesday.getFullYear() + '-' + formatDateWithLeadingZero((previousTuesday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(previousTuesday.getDate());
  }

  // Function to get the next Monday
  function getNextMonday() {
    let today = getIndianTime().getDay(); // Get the current day
    let mondayOffset = (1 - today + 7) % 7; // Calculate the offset to get to the next Monday

    let nextMonday = new Date(getIndianTime().getTime() + mondayOffset * 24 * 60 * 60 * 1000);
    return nextMonday.getFullYear() + '-' + formatDateWithLeadingZero((nextMonday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextMonday.getDate());
  }


  function getNextNextMonday() {
    let today = getIndianTime().getDay(); // Get the current day
    let mondayOffset = 7 + (1 - today + 7) % 7; // Calculate the offset to get to the next Monday

    let nextMonday = new Date(getIndianTime().getTime() + mondayOffset * 24 * 60 * 60 * 1000);
    return nextMonday.getFullYear() + '-' + formatDateWithLeadingZero((nextMonday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextMonday.getDate());
  }

  //for finnifty
  function getPreviousWednesday() {
    let today = getIndianTime().getDay(); // Get the current day
    let wednesdayOffset = (today - 3 + 7) % 7; // Calculate the offset to get to the previous Wednesday

    let previousWednesday = new Date(getIndianTime().getTime() - wednesdayOffset * 24 * 60 * 60 * 1000);
    return previousWednesday.getFullYear() + '-' + formatDateWithLeadingZero((previousWednesday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(previousWednesday.getDate());
  }

  // Function to get the next Tuesday
  function getNextTuesday() {
    let today = getIndianTime().getDay(); // Get the current day
    let tuesdayOffset = (2 - today + 7) % 7; // Calculate the offset to get to the next Tuesday

    let nextTuesday = new Date(getIndianTime().getTime() + tuesdayOffset * 24 * 60 * 60 * 1000);
    return nextTuesday.getFullYear() + '-' + formatDateWithLeadingZero((nextTuesday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextTuesday.getDate());
  }


  function getNextNextTuesday() {
    let today = getIndianTime().getDay(); // Get the current day
    let tuesdayOffset = 7 + (2 - today + 7) % 7; // Calculate the offset to get to the next Tuesday

    let nextTuesday = new Date(getIndianTime().getTime() + tuesdayOffset * 24 * 60 * 60 * 1000);
    return nextTuesday.getFullYear() + '-' + formatDateWithLeadingZero((nextTuesday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextTuesday.getDate());
  }


  function getPreviousThursday() {
    let today = getIndianTime().getDay(); // Get the current day
    let thursdayOffset = (today - 4 + 7) % 7; // Calculate the offset to get to the previous Thursday

    let previousThursday = new Date(getIndianTime().getTime() - thursdayOffset * 24 * 60 * 60 * 1000);
    return previousThursday.getFullYear() + '-' + formatDateWithLeadingZero((previousThursday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(previousThursday.getDate());
  }

  // Function to get the next Wednesday
  function getNextWednesday() {
    let today = getIndianTime().getDay(); // Get the current day
    let wednesdayOffset = (3 - today + 7) % 7; // Calculate the offset to get to the next Wednesday

    let nextWednesday = new Date(getIndianTime().getTime() + wednesdayOffset * 24 * 60 * 60 * 1000);
    return nextWednesday.getFullYear() + '-' + formatDateWithLeadingZero((nextWednesday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextWednesday.getDate());
  }

  function getNextNextWednesday() {
    let today = getIndianTime().getDay(); // Get the current day
    let wednesdayOffset = 7 + (3 - today + 7) % 7; // Calculate the offset to get to the next Wednesday

    let nextWednesday = new Date(getIndianTime().getTime() + wednesdayOffset * 24 * 60 * 60 * 1000);
    return nextWednesday.getFullYear() + '-' + formatDateWithLeadingZero((nextWednesday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextWednesday.getDate());
  }




  // Function to get the previous Friday
  function getPreviousFriday() {
    let today = getIndianTime().getDay(); // Get the current day
    let fridayOffset = (today - 5 + 7) % 7; // Calculate the offset to get to the previous Friday

    let previousFriday = new Date(getIndianTime().getTime() - fridayOffset * 24 * 60 * 60 * 1000);
    return previousFriday.getFullYear() + '-' + formatDateWithLeadingZero((previousFriday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(previousFriday.getDate());
  }

  // Function to get the next Thursday
  function getNextThursday() {
    let today = getIndianTime().getDay(); // Get the current day
    let thursdayOffset = (4 - today + 7) % 7; // Calculate the offset to get to the next Thursday

    let nextThursday = new Date(getIndianTime().getTime() + thursdayOffset * 24 * 60 * 60 * 1000);
    return nextThursday.getFullYear() + '-' + formatDateWithLeadingZero((nextThursday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextThursday.getDate());
  }

  function getNextNextThursday() {
    let today = getIndianTime().getDay(); // Get the current day
    let thursdayOffset = 7 + (4 - today + 7) % 7; // Calculate the offset to get to the next Thursday

    let nextThursday = new Date(getIndianTime().getTime() + thursdayOffset * 24 * 60 * 60 * 1000);
    return nextThursday.getFullYear() + '-' + formatDateWithLeadingZero((nextThursday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextThursday.getDate());
  }



  function getPreviousSaturday() {
    let today = getIndianTime().getDay(); // Get the current day
    let saturdayOffset = (today - 6 + 7) % 7; // Calculate the offset to get to the previous Saturday
    let previousSaturday = new Date(getIndianTime().getTime() - saturdayOffset * 24 * 60 * 60 * 1000);
    return previousSaturday.getFullYear() + '-' + formatDateWithLeadingZero((previousSaturday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(previousSaturday.getDate());
  }

  // Function to get the next Friday
  function getNextFriday() {
    let today = getIndianTime().getDay(); // Get the current day
    let fridayOffset = (5 - today + 7) % 7; // Calculate the offset to get to the next Friday
    let nextFriday = new Date(getIndianTime().getTime() + fridayOffset * 24 * 60 * 60 * 1000);
    return nextFriday.getFullYear() + '-' + formatDateWithLeadingZero((nextFriday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextFriday.getDate());
  }

  function getNextNextFriday() {
    let today = getIndianTime().getDay(); // Get the current day
    let fridayOffset = 7 + (5 - today + 7) % 7; // Calculate the offset to get to the next Friday
    let nextFriday = new Date(getIndianTime().getTime() + fridayOffset * 24 * 60 * 60 * 1000);
    return nextFriday.getFullYear() + '-' + formatDateWithLeadingZero((nextFriday.getMonth() + 1)) + '-' + formatDateWithLeadingZero(nextFriday.getDate());
  }

  useEffect(() => {
    if (data?.data.length !== 0) { //monday expairy
      // Check if today is Monday
      let today = getIndianTime().getDay();
      if (today === 1) { // Monday is 1 in JavaScript's Date object
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === "midcpnifty")
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousTuesday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextNextMonday()
        });
        setUnique(filteredArray)
      } else {
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === "midcpnifty")
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousTuesday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextMonday()
        });
        setUnique(filteredArray)
      }

    }
  }, [data])


  const handleChange = (event: any, newValue: string) => {
    if (newValue.toLowerCase() === "nifty") { //thus
      let today = getIndianTime().getDay();
      // Check if today is Thursday
      if (today === 4) {
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === newValue.toLowerCase())
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousFriday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextNextThursday()
        });
        setUnique(filteredArray)
      } else {
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === newValue.toLowerCase())
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousFriday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextThursday()
        });
        setUnique(filteredArray)
      }
      setValue(newValue);
    }


    if (newValue === "finnifty") { //tuesday expary
      let today = getIndianTime().getDay();
      // Check if the current day is Tuesday
      if (today === 2) {
        let currentDate = getIndianTime();
        // let formattedDate =
        //   currentDate.getFullYear() + '-' + formatDateWithLeadingZero((currentDate.getMonth() + 1)) + '-' + formatDateWithLeadingZero(currentDate.getDate());
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === newValue.toLowerCase())
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousWednesday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextNextTuesday()
        });
        setUnique(filteredArray);
      } else {
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === newValue.toLowerCase())
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousWednesday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextTuesday()
        });
        setUnique(filteredArray)
      }
      setValue(newValue);
    }
    if (newValue === "sensex") { // friday
      let today = getIndianTime().getDay();
      // Check if today is Thursday
      if (today === 5) {
        let currentDate = getIndianTime();
        let formattedDate =
          currentDate.getFullYear() + '-' + formatDateWithLeadingZero((currentDate.getMonth() + 1)) + '-' + formatDateWithLeadingZero(currentDate.getDate());
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === newValue.toLowerCase())
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousSaturday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextNextFriday()
        });
        setUnique(filteredArray);
      } else {
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === newValue.toLowerCase())
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousSaturday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextFriday()
        });
        setUnique(filteredArray)
      }

      setValue(newValue);

    }


    if (newValue === "banknifty") {//wed
      let today = getIndianTime().getDay();
      // Check if today is Wednesday
      if (today === 3) {
        let currentDate = getIndianTime();
        // let formattedDate =
        //   currentDate.getFullYear() + '-' + formatDateWithLeadingZero((currentDate.getMonth() + 1)) + '-' + formatDateWithLeadingZero(currentDate.getDate());

        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === newValue.toLowerCase())
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousThursday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextNextWednesday()
        });
        setUnique(filteredArray);
      } else {
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === newValue.toLowerCase())
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousThursday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextWednesday()
        });
        setUnique(filteredArray)
      }
      setValue(newValue);
    }


    if (newValue === "midcpnifty") { // monday
      let today = getIndianTime().getDay();
      // Check if today is Monday
      if (today === 1) { // Monday is 1 in JavaScript's Date object
        let currentDate = getIndianTime();
        let formattedDate =
          currentDate.getFullYear() + '-' + formatDateWithLeadingZero((currentDate.getMonth() + 1)) + '-' + formatDateWithLeadingZero(currentDate.getDate());
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === "midcpnifty")
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + day >= getPreviousTuesday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + day <= getNextNextMonday()
        });
        setUnique(filteredArray)
      } else {
        const filtered = data.data.filter((item: any) => item.child && item.child.toLowerCase() === "midcpnifty")
        const filteredArray = filtered.filter((item: any) => {
          const date = new Date(item.updatedAt);
          const year = date.getFullYear(); // Get the year (e.g., 2024)
          const month = date.getMonth() + 1; // Get the month (0-indexed, so add 1) (e.g., 3 for March)
          const day = date.getDate(); // Get the day of the month (e.g., 15)
          return year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) >= getPreviousTuesday() && year + "-" + formatMonthWithLeadingZero(month) + "-" + formatMonthWithLeadingZero(day) <= getNextMonday()
        });
        setUnique(filteredArray)
      }
      setValue(newValue);
    }

  };


  function CallTab() {
    return (
      <Tabs
        value={value}
        onChange={handleChange}
        variant={isSmallScreen ? "scrollable" : "standard"}
        centered={!isSmallScreen}
        TabIndicatorProps={{
          style: {
            backgroundColor: colors.blueAccent[900],
          }
        }}
      >
        <Tab value="midcpnifty" label="MidCpNifty" style={{ color: '#fff' }} sx={{
          '&.Mui-selected': {
            backgroundColor: colors.blueAccent[500], // Change background color when selected
            color: 'white'
          }
        }} />
        <Tab value="finnifty" label="FinNifty" style={{ color: '#fff' }} sx={{
          '&.Mui-selected': {
            backgroundColor: colors.blueAccent[500], // Change background color when selected
            color: 'white'
          }
        }} />

        <Tab value="banknifty" label="BankNifty" style={{ color: '#fff' }} sx={{
          '&.Mui-selected': {
            backgroundColor: colors.blueAccent[500], // Change background color when selected
            color: 'white'
          }
        }} />
        <Tab value="nifty" label="Nifty" style={{ color: '#fff' }} sx={{
          '&.Mui-selected': {
            backgroundColor: colors.blueAccent[500], // Change background color when selected
            color: 'white'
          }
        }} />

        <Tab value="sensex" label="Sensex" style={{ color: '#fff' }} sx={{
          '&.Mui-selected': {
            backgroundColor: colors.blueAccent[500], // Change background color when selected
            color: 'white'
          }
        }} />
      </Tabs>
    )
  }


  return (
    <><AppBar position="static">
      <Toolbar>
        <Header value={"Derivatives"} />
      </Toolbar>
    </AppBar>


      <Container>
        <div className="container">
          <div className="bloc-tabs">
            <CallTab />
          </div>
          {loading ? <CircularProgress style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }} /> :

            <div className="content-tabs">
              <TabData data={unique} />
            </div>
          }
        </div>


      </Container>


    </>

  );
}

export default Products;
