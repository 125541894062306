//https://www.youtube.com/watch?v=G-oj6TrGSrI&t=317s


const requestHeaders: HeadersInit = new Headers();
export const fetch2 = async (api: any, body: any) => {
  requestHeaders.set("Content-Type", "application/json");
  const res = await fetch(api, {
    method: "POST",
    mode: 'cors',
    headers: requestHeaders,
    body: JSON.stringify(body),
  });
  return await res.json();
};

export const fetch22 = async (api: any, userId: any, token: any) => {
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("token", `Bearer ${token}`);
  const res = await fetch(api, {
    method: "PUT",
    headers: requestHeaders,
    body: JSON.stringify(userId),
  });
  return await res.json();
};


export const fetch3 = async (api: any, type: any, token: any) => {
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("token", `Bearer ${token}`);
  const res: any = await fetch(api, {
    method: type,
    headers: requestHeaders,
  });
  return await res.json();
};


export const fetchSpecialProduct = async (api: any, type: any, body: any) => {
 try{
  const data = {
    startDate: body.startDate,
    endDate: body.endDate
  };
   requestHeaders.set("Content-Type", "application/json");
   requestHeaders.set("token", `Bearer ${body.token}`);

  const res: any = await fetch(api, {
    method: type,
    headers: requestHeaders,
    body: JSON.stringify(data)
  });
  if (!res.ok) {
    throw new Error(`HTTP error! Status: ${res.status}`);
}

const responseData = await res.json();
return responseData;

} catch (error) {
  console.error("Error:", error);
  throw error; // Rethrow the error for the caller to handle
}
};

export const fetch33 = async (api: any, type: any) => {
  requestHeaders.set("Content-Type", "application/json");
  const res: any = await fetch(api, {
    method: type,
    headers: requestHeaders,
  });
  return await res.json();
};


export const fetchBlog = async (api: any) => {
  requestHeaders.set("Content-Type", "application/json");
  const res: any = await fetch(api, {
    method: "Get",
    headers: requestHeaders,
  });
  return await res.json();
};


export const fetch1 = async (api: any, token: any, lat: any, lng: any) => {
  let body = { lat, lng };
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("token", `Bearer ${token}`);
  const res: any = await fetch(api, {
    method: "POST",
    headers: requestHeaders,
    body: JSON.stringify(body),
  });
  return await res.json();
};
