import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button, CardMedia } from '@mui/material';
import Header from "../../components/Header";
import Footer from '../../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { ThunkDispatch } from 'redux-thunk';
import { getAllBlogs } from '../../reducers/blogReducer';

function Blogs() {
    const { loading, data, check } = useSelector((state: any) => state.blog);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();



    useEffect(() => {
        dispatch(getAllBlogs())
        console.log(data.data)
    }, [])






    return (
        <>
            <Header value={'Blogs'} />
            <Container maxWidth="md">
                <Grid container spacing={3} mt={15} mb={10}>
                    {data.data && data.data.map((post: any) => (
                        <Grid item key={post._id} xs={12} sm={6}>
                            <Card>

                                <CardMedia
                                    component="img"
                                    height="250"
                                    image={post.imageUrl}
                                    alt={post.title}
                                />
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {post.title}
                                    </Typography>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {`By ${post.author} - ${ new Date(post.createdAt).getUTCDate().toString().padStart(2, '0')}-${(new Date(post.createdAt).getUTCMonth() + 1).toString().padStart(2, '0')}-${new Date(post.createdAt).getUTCFullYear()}`}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {post.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" color="primary">
                                        Read More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer />
        </>

    );
}

export default Blogs;
