import { Box, Card, CardActions, Grid, Typography } from "@mui/material";
export default function TabStocks(props: any) {

  console.log("Hi", props.data)
  console.log("Hi", props.valueFno.toUpperCase())
  var filteredArray = [];
  if (props.valueFno.toUpperCase() === "future".toUpperCase()) {
    if (Array.isArray(props.data)) {
      filteredArray = props.data.filter((item: any) => item.child.toUpperCase() === props.chilData.toUpperCase()).filter((item: any) => item.sub_child.toUpperCase() === "future".toUpperCase() ? true : item.sub_child.toUpperCase() === "daily share".toUpperCase() ? true : item.sub_child.toUpperCase() === "buy today sell tomorrow".toUpperCase() ? true : false);
    }
  } else if (props.valueFno.toUpperCase() === "Call/Put".toUpperCase()) {
    if (Array.isArray(props.data)) {
      filteredArray = props.data.filter((item: any) => item.child.toUpperCase() === props.chilData.toUpperCase()).filter((item: any) => item.sub_child.toUpperCase() === "Call/Put".toUpperCase() ? true : item.sub_child.toUpperCase() === "daily share".toUpperCase() ? true : item.sub_child.toUpperCase() === "buy today sell tomorrow".toUpperCase() ? true : false);
    }
  } else {
    if (Array.isArray(props.data)) {
      filteredArray = props.data.filter((item: any) => item.child.toUpperCase() === props.chilData.toUpperCase()).filter((item: any) => item.sub_child.toUpperCase() === "Call/Put".toUpperCase() ? true : item.sub_child.toUpperCase() === "daily share".toUpperCase() ? true : item.sub_child.toUpperCase() === "buy today sell tomorrow".toUpperCase() ? true : false);
    }
  }
  return (
    <>
      <Box sx={{ flexGrow: 1, margin: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {filteredArray?.length > 0
            ? filteredArray?.map((_value: any, index: any) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <CardActions
                    sx={{
                      flex: 1,
                      justifyContent: "space-between",
                      border: '1px solid #0000ff',
                      borderRadius: 2,
                    }}
                  >
                    <div style={{ flex: 0.5 }}>
                      <Typography align="left" color="black">
                        Name
                      </Typography>
                      <Typography align="left" color="black">
                        Price
                      </Typography>
                      <Typography align="left" color="black">
                        Target
                      </Typography>
                      <Typography align="left" color="black">
                        Stop-Loss
                      </Typography>
                      <Typography align="left" color="black">
                        Time
                      </Typography>
                      <Typography align="left" color="black">
                        Duration
                      </Typography>
                      <Typography align="left" color="black">
                        Valid
                      </Typography>
                    </div>

                    <div style={{ flex: 0.5, fontVariantCaps: "normal" }}>
                      <Typography align="left" color="red">
                        : {_value.name + " " + "(" + _value.type + ")"}
                      </Typography>
                      <Typography align="left" color="black">
                        : ₹ {_value.price}
                      </Typography>
                      <Typography align="left" color="black">
                        : ₹ {_value.target}
                      </Typography>
                      <Typography align="left" color="black">
                        : ₹ {_value.stop_loss}
                      </Typography>
                      <Typography
                        align="left"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                        color="black"
                      >
                        : 10:08 AM
                      </Typography>
                      <Typography
                        align="left"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                        color="black"
                      >
                        : {_value.duration}
                      </Typography>

                      <Typography
                        align="left"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                        color="black"
                      >
                        : {new Date(_value.updatedAt).getDate() + "-" + (new Date(_value.updatedAt).getMonth() + 1) + "-" + new Date(_value.updatedAt).getFullYear()}
                      </Typography>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
            ))
            : null}
        </Grid>
      </Box>
    </>
  );
}
