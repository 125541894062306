import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { clearState, resetPassword } from "../../reducers/authReducer";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Avatar, CircularProgress } from "@mui/material";
import logo from '../../assets/logo.png'
interface FormDataResetPassword {
  password: string;
  confirmpassword: string;
}
const ResetPassword = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { loading, data, error: errorResetPassword, check } = useSelector(
    (state: any) => state.auth
  );
  const [errors, setErrors] = useState<string | null>('');
  const { userId, token } = useParams();
  const [successMessage, setSuccessMessage] = useState('');

  const [formDataForgetPassword, setFormDataForgetPassword] = useState<FormDataResetPassword>({
    password: '',
    confirmpassword: ''
  });



  const handleForgotPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormDataForgetPassword({ ...formDataForgetPassword, [name]: value });
  };


  const handleSubmit = () => {
    if (formDataForgetPassword.password !== formDataForgetPassword.confirmpassword) {
      setErrors('Passwords do not match');
      return;
    }
    setErrors(null)
    let password = formDataForgetPassword.password;
    dispatch(resetPassword({ userId, token, password }));
  }




  useEffect(() => {
    if (check === 1) {
      setSuccessMessage(data?.msg);
      dispatch(clearState());
    }

    if (check === 3) {
      setSuccessMessage(data.msg);
      dispatch(clearState());
    }
  }, [check, data]);

  return (

    <><Header value="Reset Password" /><Container maxWidth="xs" sx={{ justifyContent: 'center', textAlign: 'center' }}>
      <Box sx={{ mt: 15 }}>

        {loading ? <CircularProgress style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }} /> :
          <>
            <Box display="flex" justifyContent="center" alignItems="center">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>

                <img src={logo} style={{ height: '100px', width: '100px', borderRadius: '50px', border: '2px solid black' }} alt="logo" />

              </div>
              {/* <Avatar alt="Avatar" sx={{ height: "15vh", width: "15vh" }} src="/path/to/image.jpg" /> */}
            </Box>
            <TextField
              fullWidth
              margin="normal"
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formDataForgetPassword.password}
              sx={{ mt: 5 }}
              onChange={handleForgotPassword}
            />


            <TextField
              fullWidth
              margin="normal"
              id="confirmpassword"
              name="confirmpassword"
              label="Confirm Password"
              type="password"
              value={formDataForgetPassword.confirmpassword}
              sx={{ mt: 1 }}
              onChange={handleForgotPassword}
              error={!!errors}
              helperText={errors}
            />


            <Button variant="contained" color="primary" sx={{ mt: 1 }} fullWidth onClick={handleSubmit}>
              Submit
            </Button>

            {successMessage && (
              <Typography variant="body1" color="primary" sx={{ mt: 10 }} align="center" gutterBottom>
                {successMessage}
              </Typography>
            )}
          </>
        }
      </Box>
    </Container>
      <Footer /></>
  );
};

export default ResetPassword;
