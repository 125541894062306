import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch3, fetch1, fetch33 } from "../helpers/fetch2";
const app_url = process.env.REACT_APP_API_URL;
// export interface DataObject {
//   data: DataArray[];
//   status: number;
// }

interface DataObject{
  data: any;
  check: number;
  loading: boolean;
  error: string;
}

// export interface DataArray {
//   _id: string;
//   userId: string;
//   free: number;
//   payment: Date;
//   createdAt: Date;
// }
// type dataState = {
//   data: DataObject;
//   loading: boolean;
//   error: string;
// };

const initialState: DataObject = {
  data: null,
  loading: false,
  error: "",
  check: 0,
};

export const getPaymentVerification = createAsyncThunk(
  "paymentVerification",
  async (body: any) => {
    const result = await fetch33(`${app_url}/payment/getVerification/${body._id}`, "get");
    return result;
  }
);

const userReducer = createSlice({
  name: "paymentVerifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentVerification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPaymentVerification.fulfilled, (state, action) => {
      state.loading = false;
      state.data =action.payload;
    });
    builder.addCase(getPaymentVerification.rejected, (state, action) => {
      state.loading = true;
    });
  },
});

export default userReducer.reducer;
