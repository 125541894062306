import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid, colors } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentComp from "./PaymentComp";
import About from "../pages/about/About";
import Privacy from "../pages/privacy/Privacy";
import Blogs from "../pages/blogs/Blogs";
import Tutorials from "../pages/tutorials/Tutorials";
import FeedbackDialog from "./FeedbackDialog";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import CheckPayment from "./CheckPayment";
export interface DataObject {
  data: DataValue[];
  status: number;
}
interface DataValue {
  username: string;
}

type IfirstChildProps = {
  token: string;
  dataValue: DataObject;
  type: number;
  loginPopUp: boolean;
  callProduct: any;
};

const CardComp: React.FC<IfirstChildProps> = (props) => {
  var [open, setOpen] = React.useState(false);


  function parentlist(value: any) {
    props.callProduct(value)

  }
  const feedback = () => {
    setOpen(!open);

  };

  return (
    <>
      {props.type === 1 ? (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
        >
          {props.dataValue.data?.length > 0
            ? props.dataValue.data.map((_value: any, index: any) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                 
                  
                >
                  

                    <CardActions
                      style={{
                        borderRadius: 10,
                        cursor: "pointer",
                        display: "flex",
                        fontVariantCaps: "petite-caps",
                      }}
                      onClick={() => parentlist(_value)}
                    >
                      <CardMedia
                        sx={{
                          height: 120,
                          width: 120,
                          resize: 'contain',
                          borderRadius: 1,
                          border: '1px solid #000000',
                        }}
                        image={_value?.imageUrl}
                        title="green"
                      />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography color="black" >{_value?.name}</Typography>
                        <Typography color="black" style={{fontSize: 13}} >{_value?.name}</Typography>
                      </div>


                    </CardActions>
              
                
                </Card>
              </Grid>
            ))
            : null}
        </Grid>
      ) : props.type === 3 ? (
        <PaymentComp />
      ) : props.type === 2 ? (
        <About />
      ) : props.type === 4 ? (
        <Privacy />
      ) : props.type === 5 ? (
        <Blogs />
      ) : props.type === 6 ? (
        <Tutorials />
      ) : (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 3 }}
        >
          <Grid item xs={2} sm={3} md={3}>
            <Card
              style={{
                color: "white",
                borderWidth: 2,
                borderColor: "#000",
                backgroundColor: "#999999",
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <CardMedia
                  sx={{
                    height: 100,
                    width: 100,
                    borderRadius: 50,
                  }}
                  image="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
                  title="green iguana"
                />
              </div>
              <>
                <CardActions
                  style={{
                    justifyContent: "center",
                    backgroundColor: "#ff7722",
                    alignItems: "center",
                    cursor: "pointer",
                    fontVariantCaps: "petite-caps",
                  }}
                  onClick={feedback}
                >
                  {open ? <FeedbackDialog open={open} /> : null}
                  <Typography color="white">Contact</Typography>
                </CardActions>
              </>
            </Card>
          </Grid>

          {/* <div className="App">
            {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <GoogleMap
                mapContainerClassName="map-container"
                center={center}
                zoom={10}
              />
            )}
          </div> */}

          <Grid item xs={1} sm={2} md={3} lg={4} xl={5}>
            <Card
              style={{
                color: "white",
                borderWidth: 2,
                borderColor: "#000",
                backgroundColor: "#999999",
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <CardMedia
                  sx={{
                    height: 100,
                    width: 100,
                    borderRadius: 50,
                  }}
                  image="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
                  title="green iguana"
                />
              </div>
              <>
                <CardActions
                  style={{
                    justifyContent: "center",
                    backgroundColor: "#ff7722",
                    alignItems: "center",
                    cursor: "pointer",
                    fontVariantCaps: "petite-caps",
                  }}
                // onClick={() => parentlist(_value)}
                >
                  <Typography color="white">Location</Typography>
                </CardActions>
              </>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CardComp;
