import React, { useState } from 'react';
import { Avatar, Box, Button, Container, TextField, Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { link, clearState } from "../reducers/authReducer";
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../assets/logo.png'
const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { loading, data, error: errorForgetPassword, check } = useSelector(
    (state: any) => state.auth
  );

  const handleForgotPassword = () => {
    if (!email) {
      setError('Email is required');
      return;
    }
    dispatch(link({ email }))
  };


  React.useEffect(() => {
    if (check === 1) {
      setSuccessMessage(`An email has been sent to ${email} with instructions to reset your password.`);
      dispatch(clearState());
    }

    if (check === 3) {
      setSuccessMessage(data.msg);
      dispatch(clearState());
    }
  }, [check, data]);

  return (
    <><Header value="Forget Password" /><Container maxWidth="xs" sx={{ justifyContent: 'center', textAlign: 'center' }}>

      <Box sx={{ mt: 15 }}>

        {loading ? <CircularProgress style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }} /> :
          <>
            <Box display="flex" justifyContent="center" alignItems="center">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>

                <img src={logo} style={{ height: '100px', width: '100px', borderRadius: '50px', border: '2px solid black' }} alt="logo" />

              </div>
              {/* <Avatar alt="Avatar" sx={{ height: "15vh", width: "15vh" }} src="/path/to/image.jpg" /> */}
            </Box>
            <TextField
              fullWidth
              margin="normal"
              id="email"
              name="email"
              label="Email Address"
              type="email"
              value={email}
              sx={{ mt: 5 }}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error}
              helperText={error} />
            <Button variant="contained" color="primary" sx={{ mt: 1 }} fullWidth onClick={handleForgotPassword}>
              Submit
            </Button>

            {successMessage && (
              <Typography variant="body1" color="primary" sx={{ mt: 10 }} align="center" gutterBottom>
                {successMessage}
              </Typography>
            )}
          </>
        }

      </Box>

    </Container><Footer /></>
  );
};

export default ForgotPasswordPage;