import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Container, Typography, Grid, Card, CardContent } from '@mui/material';

function About() {
    return (
        <>
            <Header value={'About Us'} />
            <Container maxWidth="md">
                <Grid container spacing={3} mt={15} mb={10}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Card>
                            <CardContent>

                                <Typography variant="body2" style={{ textAlign: 'justify' }}>
                                    <h3>About Us:</h3>
                                    We started our journey in 2023 to take a further step forward in providing financial aid to each and every household on their fingertips. Small investors often find themselves tangled in complicated financial terms and complex never ending chart patterns.
                                    We are here to break that norm and make financial aids easily accessible to you by our experienced analyst.
                                </Typography>

                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4> What we do:</h4>  We are a group of experienced experts who provide authentic financial advices according to need of our clients and try to give our best advice to maximise the profits of our members and satisfy our clients for longer period of time.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="body2" style={{ textAlign: 'justify' }}>
                                    <h3>Our Team</h3>
                                    <h4>1.React Native, React JS, Node JS Developers: </h4>These developers specialize in building web and mobile applications using JavaScript frameworks like React and Node.js. They handle frontend (React) and backend (Node.js) development.
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>2.AWS Specialist:</h4> This team member manages your cloud infrastructure using Amazon Web Services (AWS), which includes services like EC2, S3, RDS, Lambda, etc.
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>3.Designer:</h4> The designer is responsible for creating user interfaces (UI) and user experience (UX) designs for your applications or websites, ensuring they are visually appealing and user-friendly.
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>4.SEO Developer:</h4> This person focuses on optimizing your web content to improve its visibility and ranking on search engines like Google. They may work on technical SEO aspects, keyword research, content optimization, and link building.
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>5.Digital Marketing Specialist:</h4> This role involves promoting your products or services through digital channels such as social media, email marketing, content marketing, and paid advertising (e.g., Google Ads, Facebook Ads).
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>.Researcher:</h4>6 This team member conducts market research, competitor analysis, and user research to gather insights that inform business decisions and product development strategies.
                                </Typography>
                                <Typography variant="body2" style={{ textAlign: 'justify', marginTop: 10 }}>
                                    <h4>7.Finance Specialist:</h4> Responsible for managing the financial aspects of your project or business, including budgeting, financial analysis, forecasting, and financial reporting.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>

    );
}

export default About;
